<template>
  <div>
    <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
    <loader v-if="loading" class="fadeIn"></loader>
    <div class="single-product fadeIn" v-else>
      <div class="container" v-if="mainText">
        <LoaderAdd
          v-if="$store.state.productLoader"
          class="fadeIn loader-product"
        ></LoaderAdd>
        <div class="row" v-else>
          <div class="col-md-6">
            <div class="product-slider">
              <loader v-if="loaderSlider" />
              <div class="slider-for" v-else>
                <span
                  class="pro-stat"
                  v-if="$store.state.profileData.is_offered != 0"
                >
                  <span v-if="lang == 'en'">Sale !</span>
                  <span v-else>تخفيض !</span>
                </span>

                <div class="main-slider-product" v-if="singleProductObject">
                  <VueSlickCarousel
                    ref="slider1"
                    v-bind="sliderLoop"
                    class="single-colors"
                    @beforeChange="onBeforeChangeSlider1"
                  >
                    <div
                      v-for="single in singleProductObject.images"
                      :key="single.id"
                    >
                      <zoom-on-hover
                        :img-normal="single.image"
                        :img-zoom="single.image"
                      >
                      </zoom-on-hover>
                    </div>
                  </VueSlickCarousel>
                </div>
                <div class="main-slider-product" v-else>
                  <VueSlickCarousel
                    ref="slider1"
                    v-bind="sliderLoop"
                    class="single-colors"
                    @beforeChange="onBeforeChangeSlider1"
                  >
                    <div
                      v-for="single in $store.state.profileData.colors[0]
                        .images"
                      :key="single.id"
                    >
                      <zoom-on-hover
                        :img-normal="single.image"
                        :img-zoom="single.image"
                      >
                      </zoom-on-hover>
                    </div>
                  </VueSlickCarousel>
                </div>

                <div class="sub-slider-product" v-if="singleProductObject">
                  <VueSlickCarousel
                    ref="slider2"
                    v-bind="slider2Setting"
                    @beforeChange="onBeforeChangeSlider1"
                  >
                    <div
                      v-for="single in singleProductObject.images"
                      :key="single.id"
                    >
                      <img v-lazy="single.image" />
                    </div>
                  </VueSlickCarousel>
                </div>
                <div class="sub-slider-product" v-else>
                  <VueSlickCarousel
                    ref="slider2"
                    v-bind="slider2Setting"
                    @beforeChange="onBeforeChangeSlider1"
                  >
                    <div
                      v-for="single in $store.state.profileData.colors[0]
                        .images"
                      :key="single.id"
                    >
                      <img v-lazy="single.image" />
                    </div>
                  </VueSlickCarousel>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="product-details">
              <div class="product_breadcrumb">
                <ul>
                  <li>
                    <router-link to="/">
                      {{ mainText.account.breadcrumbs.home }}
                    </router-link>
                    <i class="fas fa-chevron-right"></i>
                  </li>
                  <li>
                    <router-link
                      :to="/category/ + $store.state.profileData.category_id"
                    >
                      {{
                        $store.state.profileData.category_name.substring(
                          0,
                          15
                        ) + "..."
                      }}
                    </router-link>
                    <i class="fas fa-chevron-right"></i>
                  </li>
                  <li>
                    <span>{{
                      $store.state.profileData.name.substring(0, 30) + "..."
                    }}</span>
                  </li>
                </ul>
              </div>
              <div class="top-product-details">
                <div class="pro-name">
                  <h3>{{ $store.state.profileData.name }}</h3>
                </div>
              </div>
              <div class="top-product-details">
                <div class="pro-name">
                  <h6><i class="fas fa-eye"></i> {{ $store.state.profileData.views }}</h6>
                </div>
              </div>
              <div class="category_name">
                <span>Category : </span>
                <router-link
                  :to="/category/ + $store.state.profileData.category_id"
                >
                  {{ $store.state.profileData.category_name }}
                </router-link>
              </div>

              <div class="price">
                <span
                  class="old"
                  v-if="$store.state.profileData.is_offered != 0"
                >
                  <span class="currency"></span>
                  {{ $store.state.profileData.price }}
                </span>
                <span
                  class="new"
                  v-if="$store.state.profileData.is_offered != 0"
                  ><span class="currency"></span>
                  {{ $store.state.profileData.offer_price }}</span
                >

                <span
                  class="new"
                  v-if="$store.state.profileData.is_offered == 0"
                >
                  <span class="currency"></span>
                  {{ $store.state.profileData.price }}
                </span>
              </div>
              <div class="des">
                <p v-html="$store.state.profileData.description"></p>
              </div>
              <div class="user-rating">
                <ul class="list-unstyled">
                  
                  <li class="full-star" v-for="star in $store.state.profileData.rate" :key="star.id">
                    <span><i class="fas fa-star"></i></span>
                  </li>
                  <li class="gray-star" v-for="star in stars - $store.state.profileData.rate" :key="star.id">
                    <span><i class="fas fa-star"></i></span>
                  </li>
                  
                </ul>

                <h5>
                  {{ this.$store.state.profileData.reviews.length }}
                  {{ mainText.product.slider.customerReviews }}
                </h5>
              </div>
              <div class="item-number">
                <p>
                  {{ mainText.product.slider.itemNum }} :
                  <span>{{ $store.state.profileData.brand_id }}</span>
                </p>
              </div>

              <div
                class="product-colors"
                v-if="$store.state.profileData.colors != ''"
              >
                <span>{{ mainText.product.slider.color }} : </span>
                <form>
                  <VueSlickCarousel
                    ref="slider2"
                    v-bind="slider22Setting"
                    @beforeChange="onBeforeChangeSlider3"
                    class="color-style-content"
                  >
                    <div
                      class="color-picker__item"
                      v-bind="color"
                      v-for="color in $store.state.profileData.colors"
                      :key="color.id"
                    >
                      <input
                        :id="'color-' + color.id"
                        type="radio"
                        class="color-picker__input"
                        name="color-input"
                        :value="color.id"
                        @change="chooseColor(color.id)"
                      />
                      <label
                        :for="'color-' + color.id"
                        class="color-picker__color"
                        :style="'background: ' + color.color"
                        @click="sliderLoopMethod(color)"
                      ></label>
                    </div>
                  </VueSlickCarousel>
                </form>
              </div>

              <div
                class="product-sizes"
                v-if="$store.state.profileData.sizes != ''"
              >
                <span>{{ mainText.product.slider.size }} : </span>
                <form>
                  <div class="size-section">
                    <template v-if="!selectedColor">
                      <div
                        class="size-picker-item"
                        v-for="size in $store.state.profileData.colors[0].sizes"
                        :key="size.id"
                      >
                        <input
                          :id="'size-' + size.id"
                          type="radio"
                          class="size-input"
                          name="size-input"
                          :value="size.id"
                          @change="chooseSize(size.id)"
                        />
                        <label :for="'size-' + size.id" class="siz-picker">
                          {{ size.size }}
                        </label>
                      </div>
                    </template>

                    <template v-else>
                      <div
                        class="size-picker-item"
                        v-for="size in selectedColor.sizes"
                        :key="size.id"
                      >
                        <input
                          :id="'size-' + size.id"
                          type="radio"
                          class="size-input"
                          name="size-input"
                          :value="size.id"
                          @change="chooseSize(size.id)"
                        />
                        <label :for="'size-' + size.id" class="siz-picker">
                          {{ size.size }}
                        </label>
                      </div>
                    </template>
                  </div>
                </form>
              </div>

              <div class="counter-add-cart">
                <div class="input-group counter">
                  <div class="input-group-minus">
                    <button
                      class="input-group-text btn-minus"
                      @click="counterMinus"
                    >
                      -
                    </button>
                  </div>
                  <input type="text" class="form-control" v-model="counter" />
                  <div class="input-group-plus">
                    <button
                      class="input-group-text btn-plus"
                      @click="counterAdd"
                      :disabled="counter >= selectedColorsSizeAvailableAmount"
                    >
                      +
                    </button>
                  </div>
                </div>

                <button
                  type="button"
                  ref="add"
                  @click="addToCart($store.state.profileData.name)"
                  class="dark-button button btn main-btn add-to-cart"
                  :disabled="selectedColorsSizeAvailableAmount == 0"
                >
                  <i class="fas fa-spinner"></i>
                </button>
              </div>
              <div class="add_to_wishlist_ptn">
                <div class="like">
                  <ul class="list-unstyled m-0" v-if="token">
                    <li class="like">
                      <button
                        @click="
                          $set(
                            $store.state.profileData,
                            'isActive',
                            !$store.state.profileData.isActive
                          ),
                            doLike($store.state.profileData.id)
                        "
                        v-if="$store.state.profileData.is_favourite == false"
                        :class="[
                          'btn',
                          { likee: $store.state.profileData.isActive },
                          { dislikee: !$store.state.profileData.isActive },
                        ]"
                      >
                        <span class="fadeIn" v-if="lang == 'en'">
                          Add To Wishlist
                        </span>
                        <span class="fadeIn" v-else>
                          اضافة إلي قائمة الرغبات
                        </span>
                      </button>
                      <!-- like -->
                      <button
                        @click="
                          $set(
                            $store.state.profileData,
                            'isActive',
                            !$store.state.profileData.isActive
                          ),
                            doLike($store.state.profileData.id)
                        "
                        v-else
                        :class="[
                          'btn',
                          { likee: !$store.state.profileData.isActive },
                          { dislikee: $store.state.profileData.isActive },
                        ]"
                      >
                        <span class="fadeIn" v-if="lang == 'en'">
                          Remove From Wishlist
                        </span>
                        <span class="fadeIn" v-else>
                          حذف من قائمة الرغبات
                        </span>
                      </button>
                    </li>
                  </ul>
                </div>
                <div class="share">
                  <ul class="list-unstyled" :class="{ show: showShare }">
                    <li>
                      <ShareNetwork
                        network="facebook"
                        :url="pageLocation"
                        :title="$store.state.profileData.name"
                        :description="$store.state.profileData.description"
                        :quote="$store.state.profileData.name"
                        :media="$store.state.profileData.images[0].image"
                        hashtags="octogy"
                      >
                        <span class="icon"
                          ><i class="fab fa-facebook-f"></i
                        ></span>
                      </ShareNetwork>
                    </li>
                    <li>
                      <ShareNetwork
                        network="twitter"
                        :url="pageLocation"
                        :title="$store.state.profileData.name"
                        :description="$store.state.profileData.description"
                        :quote="$store.state.profileData.name"
                        :media="$store.state.profileData.images[0].image"
                        hashtags="octogy"
                      >
                        <span class="icon"><i class="fab fa-twitter"></i></span>
                      </ShareNetwork>
                    </li>
                  </ul>
                  <button type="button" class="btn" @click="shareSow">
                    <span class="icon"><i class="fas fa-share"></i></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- error alert -->
    <transition name="slide-fade">
      <div class="error-alert" v-if="error.static.error">
        <span @click="error.static.error = false">
          <i class="fas fa-times"></i>
        </span>
        <p>{{ error.static.text }}</p>
      </div>
    </transition>
    <!-- success alert -->
    <transition name="slide-fade">
      <div class="success-alert" v-if="success">
        <span @click="success = false"> <i class="fas fa-times"></i> </span>
        <p>{{ successText }}</p>
      </div>
    </transition>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import axios from "axios";
import loader from "../../Loading/main-slider";
import LoaderAdd from "../../Loading/Loading";
import textAr from "../../lang/ar.json";
import textEn from "../../lang/en.json";
export default {
  data() {
    return {
      items: [1, 2, 3, 4, 5],
      loading: false,
      rate: "",
      stars: 5,
      counter: 1,
      colorId: "",
      // START:: SELECTED DATA
      selectedColor: null,
      selectedColorsSizeAvailableAmount: null,
      // END:: SELECTED DATA
      loaderSlider: false,
      sizeId: "",
      singleProductObject: null,
      valueName: "",
      id: this.$route.params.id,
      successText: "",
      // error
      error: {
        static: {
          error: false,
          text: "",
        },
      },
      pageLocation: window.location.href,
      showShare: false,
      success: false,
      token: localStorage.getItem("shop-token"),
      lang: localStorage.getItem("shop_lang"),
      mainText: "",
      slider1Setting: {
        arrows: false,
        dots: false,
        asNavFor: this.$refs.slider2,
        focusOnSelect: true,
      },

      sliderLoop: {
        arrows: true,
        dots: false,
        // asNavFor: this.$refs.slider1,
        focusOnSelect: true,
      },
      slider2Setting: {
        arrows: true,
        dots: false,
        asNavFor: this.$refs.slider1,
        focusOnSelect: true,
        slidesToShow: 4,
        draggable: false,
      },
      slider22Setting: {
        arrows: true,
        dots: false,
        asNavFor: this.$refs.slider1,
        focusOnSelect: true,
        slidesToShow: 12,
        draggable: false,
      },
      // productTitle: this.$store.state.profileData.name,
      // $store.state.profileData.name
    };
  },
  components: {
    VueSlickCarousel,
    loader,
    LoaderAdd,
  },

  methods: {
    sliderLoopMethod(singleColor) {
      this.singleProductObject = singleColor;
      // console.log(singleProductObject);
    },
    onBeforeChangeSlider1(currentPosition, nextPosition) {
      this.$refs.slider1.goTo(nextPosition);
      this.$refs.slider2.goTo(nextPosition);
    },
    onBeforeChangeSlider2(currentPosition, nextPosition) {
      this.$refs.slider1.goTo(nextPosition);
      this.$refs.slider2.goTo(nextPosition);
    },
    onBeforeChangeSlider3(currentPosition, nextPosition) {
      this.$refs.slider1.goTo(nextPosition);
      this.$refs.slider2.goTo(nextPosition);
    },

    counterAdd() {
      this.counter++;
    },
    counterMinus() {
      if (this.counter > 1) {
        this.counter--;
      }
    },
    chooseColor(colorId) {
      this.colorId = colorId;

      let targetColor = this.$store.state.profileData.colors.find(
        (element) => element.id == colorId
      );
      this.selectedColor = targetColor;
      // console.log(targetColor);
    },
    chooseSize(sizeId) {
      this.sizeId = sizeId;

      let selectedSize = this.selectedColor.sizes.find(
        (element) => element.id == sizeId
      );
      this.selectedColorsSizeAvailableAmount = selectedSize.quantity;
    },
    doLike(id) {
      this.$store.dispatch("addFavourite", { id });
    },
    getData() {
      this.loading = true;
      axios
        .get("/general/product-details/" + this.id, {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          this.loading = false;
          this.$store.state.profileData = res.data.data;
          this.selectedColor = this.$store.state.profileData.colors[0];
          this.rate = parseInt(res.data.data.rate);
        })
        .finally(() => (this.loading = false));
    },
    addToCart(name) {
      if (!this.token) {
        if (this.lang == "ar") {
          this.error.static.error = true;
          this.error.static.text = "يجب عليك تسجيل الدخول اولا";
          setTimeout(() => {
            this.error.static.error = false;
          }, 3000);
        } else {
          this.error.static.error = true;
          this.error.static.text = "You Must Login";
          setTimeout(() => {
            this.error.static.error = false;
          }, 3000);
        }
      } else {
        this.error.static.error = false;
        this.success = false;
        const submitButton = this.$refs["add"];
        submitButton.classList.add("click");
        // this.loadAdd = true;
        this.valueName = name;
        const data = new FormData();
        data.append("product_id", this.id);
        data.append("quantity", this.counter);

        if (this.colorId !== null) {
          data.append("color_id", this.colorId);
        }
        if (this.sizeId !== null) {
          data.append("size_id", this.sizeId);
        }
        axios
          .post("/carts", data, {
            headers: {
              Authorization: "bearer" + localStorage.getItem("shop-token"),
              "cache-control": "no-cache",
              "Content-type": "application/json",
              Accept: "application/json",
              lang: localStorage.getItem("shop_lang"),
            },
          })
          .then((res) => {
            this.$store.state.cartList;
            this.$store.state.cartCountProduct.product_count;
            this.$store.dispatch("getCart");
            this.counter = 1;
            setTimeout(() => {
              // this.loadAdd = false;
              new Audio(
                require("../../../../assets/audio/notification.mp3")
              ).play();
              submitButton.classList.remove("click");
              this.error.static.error = false;
              this.success = true;
              if (res.status == 201) {
                this.successText =
                  name + this.mainText.product.slider.productAdded;
                const cartNumber = document.getElementById("number-cart");
                cartNumber.innerHTML++;
              } else if (res.status == 200) {
                this.success = false;
                this.error.static.error = true;
                this.error.static.text =
                  this.mainText.product.slider.productExist;
              }
            }, 1000);

            setTimeout(() => {
              this.success = false;
            }, 3000);
          })
          .catch((error) => {
            this.error.static.error = true;
            this.error.static.text = error.response.data.msg;
            setTimeout(() => {
              //  this.loadAdd = false;
              submitButton.classList.remove("click");
              this.error.static.error = false;
            }, 1000);
            // if (!this.token) {
            //   this.$router.push({ path: "/login" });
            // }
          });
      }
    },
    shareSow() {
      this.showShare = !this.showShare;
    },
  },

  // START:: VUE-META

  // metaInfo() {
  //   setTimeout(() => {
  //     return {
  //       title: `Davina Store | داڤينا استور - ${this.productTitle}`,
  //       meta: [
  //         {
  //           name: "description",
  //           content: "Starter App Description Will Go Here",
  //         },
  //       ],
  //     };
  //   }, 2000);
  // },

  // END:: VUE-META

  mounted() {
    this.$store.dispatch("getProfileDetails", { product_id: this.id });
    this.$store.dispatch("getCart");

    this.getData(this.id);

    this.sliderLoopMethod();

    if (this.lang == "en") {
      this.mainText = textEn;
    } else {
      this.mainText = textAr;
    }
  },
};
</script>
