<template>
  <div class="conteiner_slider">
    <div class="main-slider">
      <Loader v-if="$store.state.mainSliderLoading"></Loader>
      <carousel
        v-else
        :autoplay="true"
        class="owl-header fadeIn"
        :nav="false"
        :dots="true"
        :items="1"
        :loop="true"
        :center="true"
      >
        <div class="item" v-for="item in $store.state.homeData.galleries" :key="item.id">
          <router-link to="/best-offers">
            <img :src="item.image" class="img-responsive" />
          </router-link>
        </div>
      </carousel>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel2";
import Loader from "./../Loading/main-slider.vue";
import axios from "axios";
export default {
  data() {
    return {
      show: false,
      display: false,
      loading: true,
      allData: "",
      social: null,
    };
  },
  mounted() {
    this.getSocial();
  },
  methods: {
    
    getSocial() {
      this.loading = true;
      axios
        .get("/general/settings", {
          headers: {
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          this.loading = false;
          this.social = res.data.data.contact_us;
        })
        .finally(() => (this.loading = false));
    },
  },
  components: {
    carousel,
    Loader,
  },
};
</script>
