<template>
  <div>
    <!--start signin-->
    <div class="signin signup" v-if="mainText">
      <div class="container">
        <div class="row justify-content-center align-self-center">
          <div class="col-md-6">
            <div class="sign-form">
              <div class="logo">
                <router-link to="/" tag="a">
                  <img class="img-responsive" src="@/assets/images/logo-white.png" />
                </router-link>
                <p>{{ mainText.register.title }}</p>
              </div>
              <form>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        :placeholder="mainText.register.form.fullName"
                        v-model="user.f_name"
                        v-on:keydown.enter.prevent="register"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        :placeholder="mainText.register.form.userName"
                        v-model="user.l_name"
                        v-on:keydown.enter.prevent="register"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group">
                      <input
                        type="email"
                        class="form-control"
                        :placeholder="mainText.register.form.email"
                        v-model="user.email"
                        v-on:keydown.enter.prevent="register"
                      />
                    </div>
                  </div>
                  <!-- <div class="col-4 col-sm-3">
                    <div class="form-group">
                      <select
                        name="options[212]"
                        class="form-control diaa-select"
                      >
                        <option value="option-1" data-src="images/suadi.png">
                          +966
                        </option>
                        <option value="option-2" data-src="images/suadi.png">
                          +966
                        </option>
                        <option value="option-3" data-src="images/suadi.png">
                          +966
                        </option>
                      </select>
                    </div>
                  </div> -->
                  <div class="col-12 col-sm-12">
                    <div class="form-group">
                      <input
                        type="tel"
                        class="form-control"
                        :placeholder="mainText.register.form.phone"
                        v-model="user.phone"
                        v-on:keydown.enter.prevent="register"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group">
                      <input
                        type="password"
                        class="form-control"
                        :placeholder="mainText.register.form.password"
                        v-model="user.password"
                        v-on:keydown.enter.prevent="register"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group">
                      <input
                        type="password"
                        class="form-control"
                        :placeholder="mainText.register.form.confirmPassword"
                        v-model="user.confirm_password"
                        v-on:keydown.enter.prevent="register"
                      />
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  class="dark-button register-button button btn main-btn"
                  ref="register"
                  @click="register"
                >
                  <i class="fas fa-spinner"></i>
                </button>
              </form>
              <div class="bottom-sign">
                <div class="social-sign">
                  <!-- <div class="sign-google">
                    <a @click="loginWithGoogle"
                      ><i class="fab fa-google"></i>
                      {{ mainText.login.form.signgoogle }}</a
                    >
                  </div> -->
                  <!-- <div class="sign-facebook" @click="loginWithFacebook">
                    <a
                      ><i class="fab fa-facebook-f"></i>
                      {{ mainText.login.form.signface }}</a
                    >
                  </div> -->
                </div>
                <div class="have-account">
                  <p>
                    {{ mainText.register.form.dontHave }}
                    <router-link to="/login">
                      {{ mainText.register.form.signUp }}</router-link
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="shape">
        <img src="@/assets/images/shapes/shape2.png" />
      </div>
    </div>
    <!-- error alert -->
    <transition name="slide-fade">
      <div class="error-alert" v-if="error.static.error">
        <span @click="error.static.error = false">
          <i class="fas fa-times"></i>
        </span>
        <p>{{ error.static.text }}</p>
      </div>
    </transition>
    <!-- success alert -->
    <transition name="slide-fade">
      <div class="success-alert" v-if="success">
        <span @click="success = false"> <i class="fas fa-times"></i> </span>
        <p>{{ mainText.login.form.successLogin }}</p>
      </div>
    </transition>
    <!--end signin-->
  </div>
</template>

<script>
import axios from "axios";
import textAr from "../../lang/ar.json";
import textEn from "../../lang/en.json";
import firebase from "firebase";
export default {
  name: "register-user",
  data() {
    return {
      // data
      user: {
        f_name: "",
        l_name: "",
        email: "",
        phone: "",
        password: "",
        confirm_password: "",
      },
      error: {
        static: {
          error: false,
          text: "",
        },
      },
      success: false,
      lang: localStorage.getItem("shop_lang"),
      mainText: "",
    };
  },
  mounted() {
    var userType = localStorage.getItem("shop-token");
    if (userType) {
      this.$router.push({ path: "/" });
    }
    if (this.lang == "en") {
      this.mainText = textEn;
    } else {
      this.mainText = textAr;
    }
  },
  methods: {
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    loginWithFacebook() {},
    loginWithGoogle() {
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(() => {
          this.sendGoogleLogin();
        })
        .catch((err) => {
          alert(err.message);
        });
    },
    sendGoogleLogin() {
      const data = new FormData();
      data.append("full_name", firebase.auth().currentUser.displayName);
      data.append("user_name", firebase.auth().currentUser.displayName);
      data.append("email", firebase.auth().currentUser.email);
      data.append("provider_type", "google");
      data.append("access_token", firebase.auth().currentUser.uid);
      axios
        .post("/auth/social", data, {
          headers: {
            "cache-control": "no-cache",
            "Content-type": `multipart/form-data;`,
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          localStorage.setItem("shop-token", res.data.data.token);
          this.$router.push({ path: "/" });
        });
    },
    // register
    register() {
      const submitButton = this.$refs["register"];
      submitButton.classList.add("click");
      this.error.errorEmail = false;
      this.error.errorPassword = false;
      this.error.static.error = false;
      if (this.user.f_name == "") {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.error.static.error = true;
          this.error.static.text = this.mainText.register.errors.fullName;
          this.success = false;
        }, 1000);
      } else if (this.user.l_name == "") {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.error.static.error = true;
          this.error.static.text = this.mainText.register.errors.userName;
          this.success = false;
        }, 1000);
      } else if (this.user.email == "") {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.error.static.error = true;
          this.error.static.text = this.mainText.register.errors.email;
          this.success = false;
        }, 1000);
      } else if (!this.validEmail(this.user.email)) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.error.static.error = true;
          this.error.static.text = "email is invalid";
          this.success = false;
        }, 1000);
      } else if (this.user.phone == "") {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.error.static.error = true;
          this.error.static.text = this.mainText.register.errors.phone;
          this.success = false;
        }, 1000);
      } else if (this.user.password == "") {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.error.static.error = true;
          this.error.static.text = this.mainText.register.errors.password;
          this.success = false;
        }, 1000);
      } else if (this.user.password !== this.user.confirm_password) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.error.static.error = true;
          this.error.static.text = this.mainText.register.errors.confirmPassword;
          this.success = false;
        }, 1000);
      } else {
        const data = new FormData();
        data.append("full_name", this.user.f_name);
        data.append("user_name", this.user.l_name);
        data.append("email", this.user.email);
        data.append("phone", this.user.phone);
        data.append("password", this.user.password);
        data.append("password_confirmation", this.user.confirm_password);
        axios
          .post("/auth/register", data, {
            headers: {
              "cache-control": "no-cache",
              "Content-type": `multipart/form-data;`,
              Accept: "application/json",
              lang: "en",
            },
          })
          .then((res) => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.error.static.error = false;
              this.success = true;
              localStorage.setItem("shop-email", res.data.data.email);
              localStorage.setItem("shop-code", res.data.data.code);
            }, 1000);
            setTimeout(() => {
              this.$router.push({ path: "/enter-code" });
            }, 2000);
          })
          .catch((error) => {
            this.error.static.error = true;
            submitButton.classList.remove("click");
            this.waiting = false;
            this.error.errorEmail = false;
            this.error.errorPassword = false;
            this.error.static.text = error.response.data.msg;
          })
          .finally(() => (this.waiting = false));
      }
    },
  },
  components: {},
};
</script>
