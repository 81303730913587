var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"our-partners"},[(_vm.$store.state.partenrsLoading)?_c('Loader',{staticClass:"fadeIn"}):_c('div',{staticClass:"container"},[(_vm.$store.state.homeData.partners == '')?_c('NotFound'):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('carousel',{staticClass:"owl-partners fadeIn",attrs:{"items":5,"autoplay":true,"loop":true,"dots":false,"responsive":{
            0: {
              items: 1,
              nav: false,
            },
            600: {
              items: 2,
              nav: false,
            },
            800: {
              items: 5,
              nav: false,
            },
          }}},_vm._l((this.$store.state.homeData.partners),function(item){return _c('div',{key:item.id,staticClass:"item"},[_c('div',{staticClass:"image"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":item.image}})])],1)])}),0)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }