<template>
  <div>
    <!--start page-banner-->

    <div class="page-banner">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <!--breadcrumbs-->
            <div class="breadcrumbs">
              <h3 v-if="lang == 'en'">All Categories</h3>
              <h3 v-if="lang == 'ar'">كل الاقسام</h3>
              <ul class="list-unstyled">
                <li>
                  <router-link to="/">{{ mainText.home.title }} </router-link>>
                </li>
                <li v-if="lang == 'en'">All Categories</li>
                <li v-if="lang == 'ar'">كل الاقسام</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end page-banner-->

    <!--start product-category-->

    <section class="product-category">
      <div class="container">
        <div class="all-filters">
          <div class="row fadeIn">
            <div class="col-md-4">
              <div class="right-filter">
                <div class="filterby">
                  <a @click="showFilterMenu()"
                    ><img src="@/assets/images/metro-equalizer.png"
                  /></a>
                  <span v-if="lang === 'en'">Filter By</span>
                  <span v-else>بحث بواسطة </span>
                </div>
                <!-- <div class="pagination">
                  <ul class="list-unstyled">
                    <li><a href="">2</a></li>
                    <li><a href="">3</a></li>
                    <li><a href="">4</a></li>
                  </ul>
                </div> -->
              </div>
            </div>
            <div class="col-md-4" v-if="allData.length != 0">
              <div class="filt-num text-center">
                <h4 v-if="lang === 'en'">{{ allData.length }} Products</h4>
                <h4 v-else>{{ allData.length }} منتج</h4>
              </div>
            </div>

            <div class="col-md-4" v-if="allData.length != 0"></div>
          </div>
        </div>
        <Loader v-if="loading" class="fadeIn"></Loader>
        <div class="products fadeIn" v-else>
          <div class="fadeIn" v-if="allData == ''">
            <NotFound></NotFound>
          </div>
          <div class="row fadeIn" v-else>
            <!-- product card -->
            <div
              class="col-lg-3 col-md-6 fadeIn"
              v-for="item in filteredItems"
              :key="item.id"
            >
              <div class="one-product">
                <!-- product image-->
                <router-link :to="'/product/' + item.id">
                  <div class="product-img">
                    <img :src="item.main_image" />
                  </div>
                </router-link>
                <!-- product description -->
                <div class="product-exp">
                  <!-- product title -->
                  <div class="product-title">
                    <h5>{{ item.name }}</h5>
                    <span> {{ item.brand_name }} </span>
                  </div>
                  <!-- product add , show and like -->
                  <div class="price-fav">
                    <div class="price">
                      <span class="new-price" v-if="item.is_offered != 0"
                        ><span class="currency"></span>
                        {{ item.offer_price }}</span
                      >
                      <span class="new-price" v-if="item.is_offered == 0"
                        ><span class="currency"></span> {{ item.price }}</span
                      >
                      <span class="old-price" v-if="item.is_offered != 0"
                        ><span class="currency"></span> {{ item.price }}</span
                      >
                    </div>
                    <ul class="list-unstyled" v-if="token">
                      <li class="like">
                        <!-- <button
                            @click="toggleheart"
                            class="icon btn"
                            type="button"
                            :class="{ active: like }"
                          >
                            <span class="icon unfav"
                              ><i class="fas fa-heart"></i
                            ></span>
                            <span class="icon fav"
                              ><i class="far fa-heart"></i
                            ></span>
                          </button> -->
                        <!-- no like -->
                        <button
                          @click="
                            $set(item, 'isActive', !item.isActive),
                              doLike(item.id)
                          "
                          v-if="item.is_favourite == false"
                          :class="[
                            'btn',
                            { likee: item.isActive },
                            { dislikee: !item.isActive },
                          ]"
                        ></button>
                        <!-- like -->
                        <button
                          @click="
                            $set(item, 'isActive', !item.isActive),
                              doLike(item.id)
                          "
                          v-else
                          :class="[
                            'btn',
                            { likee: !item.isActive },
                            { dislikee: item.isActive },
                          ]"
                        ></button>
                      </li>
                    </ul>
                  </div>
                </div>
                <span class="pro-stat" v-if="item.is_offered != 0"
                  >{{ item.discount }} %</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        @click="hideFilterMenu()"
        class="overlay"
        :class="{ disblock: show }"
      ></div>
      <!-- pagenation -->
      <div class="pagenation" v-if="lastPage > 1">
        <ul>
          <li class="page-item" :class="page === 1 ? 'disabled' : ''">
            <button type="button" class="page-link" @click="prevPage">
              <span class="icon">
                <i class="fas fa-chevron-left"></i>
              </span>
            </button>
          </li>
          <li>
            <h3 class="page-text">{{ page }} of {{ lastPage }}</h3>
          </li>
          <li class="page-item" :class="page === lastPage ? 'disabled' : ''">
            <button type="button" class="page-link" @click="nextPage">
              <i class="fas fa-chevron-right"></i>
            </button>
          </li>
        </ul>
      </div>
    </section>
    <!-- filter -->
    <div class="filter-menu" :class="{ show: show }">
      <a @click="hideFilterMenu()" class="close-filter only-mobile"
        ><i class="fas fa-times"></i
      ></a>
      <div class="filter-title">
        <h4 v-if="lang === 'en'">Filter</h4>
        <h4 v-else>بحث بواسطة</h4>
        <form>
          <div class="form-group">
            <h4 v-if="lang === 'en'">Popularity</h4>
            <h4 v-else>الأكثر شيوعاّ</h4>
            <select
              class="custom-select"
              v-if="lang === 'en'"
              v-model="filter.popularity"
              @change="getData"
            >
              <option value="">choose from here</option>
              <option value="popularity">popularity</option>
              <option value="">no popularity</option>
            </select>
            <select
              class="custom-select"
              v-else
              v-model="filter.popularity"
              @change="getData"
            >
              <option value="">إختر من هنا</option>
              <option value="popularity">أكثر شيوعاّ</option>
              <option value="">أقل شيوعاّ</option>
            </select>
          </div>
          <div class="form-group">
            <h4 v-if="lang === 'en'">Categories</h4>
            <h4 v-else>الاقسام</h4>
            <select
              class="custom-select"
              v-model="filter.categoryId"
              @change="getData"
            >
              <option value="" v-if="lang === 'en'">choose from here</option>
              <option value="" v-else>إختر من هنا</option>
              <option v-for="cat in categories" :key="cat.id" :value="cat.id">
                {{ cat.name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <h4 v-if="lang === 'en'">Brands</h4>
            <h4 v-else>الماركات</h4>
            <select
              class="custom-select"
              v-model="filter.brand"
              @change="getData"
            >
              <option value="" v-if="lang === 'en'">choose from here</option>
              <option value="" v-else>إختر من هنا</option>
              <option v-for="brand in brands" :key="brand.id" :value="brand.id">
                {{ brand.name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <h4 v-if="lang === 'en'">Price</h4>
            <h4 v-else>السعر</h4>
            <div class="price-slider">
              <input
                min="1"
                max="50000"
                step="10"
                type="range"
                v-model="min"
                @change="minPrice(min)"
              />
              <input
                min="1"
                max="50000"
                step="10"
                type="range"
                v-model="max"
                @change="maxPrice(max)"
              />
            </div>
            <div class="price-range">
              <span class="min"><span class="currency"></span> {{ min }}</span>
              <span class="max"><span class="currency"></span> {{ max }}</span>
            </div>
          </div>
          <div class="form-group">
            <div class="filter-color">
              <h4 v-if="lang === 'en'">Color</h4>
              <h4 v-else>اللون</h4>
              <span class="icon"><i class="fas fa-angle-down"></i></span>
            </div>
            <div class="my-colors">
              <div class="product-colors">
                <form>
                  <div class="color-section">
                    <div
                      class="color-picker__item"
                      v-for="color in colors"
                      :key="color.id"
                    >
                      <input
                        :id="'a' + color.id"
                        type="radio"
                        class="color-picker__input"
                        name="color-input"
                        :value="color.color"
                        @change="chooseColor(color.color)"
                      />
                      <label
                        :for="'a' + color.id"
                        class="color-picker__color"
                        :style="'background : ' + color.color"
                      ></label>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="form-group">
            <h4 v-if="lang === 'en'">Rating</h4>
            <h4 v-else>التقييمات</h4>
            <select
              class="custom-select"
              v-model="filter.rate"
              @change="getData"
            >
              <option value="" v-if="lang === 'en'">choose from here</option>
              <option value="" v-else>إختر من هنا</option>
              <option value="0">0 Stars</option>
              <option value="1">1 Stars</option>
              <option value="2">2 Stars</option>
              <option value="3">3 Stars</option>
              <option value="4">4 Stars</option>
              <option value="5">5 Stars</option>
            </select>
          </div>
          <div class="filter-btns">
            <button
              @click="resetFilter"
              type="button"
              class="thd-btn btn-block"
              v-if="lang === 'en'"
            >
              Reset Filter
            </button>
            <button
              @click="resetFilter"
              type="button"
              class="thd-btn btn-block"
              v-else
            >
              عرض كل المنتجات
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "../Loading/cards";
import NotFound from "./../Loading/not-found";
import textAr from "../lang/ar.json";
import textEn from "../lang/en.json";
export default {
  data() {
    return {
      loading: false,
      show: false,
      allData: "",
      cat_name: "",
      brands: "",
      colors: "",
      categories: "",
      filter: {
        categoryId: "",
        popularity: "",
        brand: "",
        color: "",
        rate: "",
      },
      filteredData: "",

      token: localStorage.getItem("shop-token"),
      // pagenation
      page: 1,
      perPage: 12,
      lang: localStorage.getItem("shop_lang"),
      mainText: "",
      min: 1,
      max: 50000,
    };
  },
  mounted() {
    this.getTitle();
    this.getData();
    this.filterBrands();
    this.filterColors();
    this.filterCategories();
    if (this.lang == "en") {
      this.mainText = textEn;
    } else {
      this.mainText = textAr;
    }
  },
  methods: {
    chooseColor(id) {
      this.filter.color = id;
      this.getData();
    },
    minPrice(price) {
      this.filter.min = price;
      this.getData();
    },
    // minPrice(price) {
    //   this.filter.min = price;
    //   this.getData();
    // },
    maxPrice(price) {
      this.filter.max = price;
      this.getData();
    },
    resetFilter() {
      this.filter.categoryId = "";
      this.filter.popularity = "";
      this.filter.brand = "";
      this.filter.color = "";
      this.filter.rate = "";
      this.getData();
    },
    getTitle() {
      axios
        .get("/general/productsByCatID?category_id=" + this.filter.id, {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          this.cat_name = res.data.cat_name;
        });
    },
    getData() {
      this.loading = true;
      const data = new FormData();
      data.append("popularity", this.filter.popularity);
      data.append("category_id", this.filter.categoryId);
      data.append("brand_id", this.filter.brand);
      data.append("color", this.filter.color);
      data.append("rate", this.filter.rate);
      data.append("min_price", this.min);
      data.append("max_price", this.max);
      axios
        .post("/general/filter-data", data, {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          this.loading = false;
          this.page = 1;
          this.allData = res.data.data;
        });
    },
    filterCategories() {
      axios
        .get("/general/all-categories", {
          headers: {
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          this.categories = res.data.data;
        });
    },
    filterBrands() {
      axios
        .get("/general/all-brands", {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            lang: "en",
          },
        })
        .then((res) => {
          this.brands = res.data.data;
          // this.get_filter();
        });
    },
    filterColors() {
      axios
        .get("/general/all-colors", {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            lang: "en",
          },
        })
        .then((res) => {
          this.colors = res.data.data;
        });
    },
    // add to favourite
    doLike(id) {
      this.loading = true;
      const data = new FormData();
      data.append("product_id", id);
      axios
        .post("/favourite", data, {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then(() => {
          this.loading = false;
        });
    },
    showFilterMenu() {
      this.show = true;
    },
    hideFilterMenu() {
      this.show = false;
    },
    // pagenation
    prevPage() {
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });
      this.page--;
    },
    nextPage() {
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });
      this.page++;
    },
  },
  computed: {
    filteredItems() {
      let start = (this.page - 1) * this.perPage;
      let end = start + this.perPage;
      return this.allData.slice(start, end);
    },
    lastPage() {
      let length = this.allData.length;
      return Math.floor(length / this.perPage) + 1;
    },
  },

  components: {
    Loader,
    NotFound,
  },
};
</script>

<style scoped>
.filter-color {
  display: flex;
  margin-bottom: 10px;
}
.filter-color span.icon {
  font-size: 20px !important;
  font-weight: 500 !important;
  display: none;
}
.my-colors .color-section {
  display: flex;
  padding: 0 1.75rem 0 0.75rem;
}
.my-colors .product-colors form {
  margin: 0 !important;
}

.my-colors .color-picker__color {
  border-radius: 50px;
}

input[type="range"] {
  box-shadow: 0;
  outline: 0;
}
.price-slider {
  width: 100%;
  margin: auto;
  text-align: center;
  position: relative;
  height: 15px;
  margin-bottom: 15px;
}
.price-slider svg,
.price-slider input[type="range"] {
  position: absolute;
  left: 0;
  bottom: 0;
}
input[type="number"] {
  border: 1px solid #ddd;
  text-align: center;
  font-size: 1.6em;
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"]:invalid,
input[type="number"]:out-of-range {
  border: 2px solid #e60023;
}
input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #034b9d;
}
input[type="range"]:focus::-ms-fill-lower {
  background: #034b9d;
}
input[type="range"]:focus::-ms-fill-upper {
  background: #034b9d;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: #034b9d;
  border-radius: 1px;
  box-shadow: none;
  border: 0;
}
input[type="range"]::-webkit-slider-thumb {
  z-index: 2;
  position: relative;
  box-shadow: 0px 0px 0px #000;
  border: 1px solid #4e85c3;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #4e85c3;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: #034b9d;
  border-radius: 1px;
  box-shadow: none;
  border: 0;
}
input[type="range"]::-moz-range-thumb {
  z-index: 2;
  position: relative;
  box-shadow: 0px 0px 0px #000;
  border: 1px solid #034b9d;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #3977be;
  cursor: pointer;
}
input[type="range"]::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type="range"]::-ms-fill-lower,
input[type="range"]::-ms-fill-upper {
  background: #034b9d;
  border-radius: 1px;
  box-shadow: none;
  border: 0;
}
input[type="range"]::-ms-thumb {
  z-index: 2;
  position: relative;
  box-shadow: 0px 0px 0px #000;
  border: 1px solid #034b9d;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #3977be;
  cursor: pointer;
}

.price-range {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.price-range span {
  font-size: 16px;
}
</style>
