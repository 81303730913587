<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="account-details" v-else>
      <div class="my-form">
        <form>
          <div class="edit-account">
            <h3>{{ mainText.account.editAccount.title }}</h3>
            <div class="form-group">
              <div class="row">
                <div class="col-md-3">
                  <label class="">{{
                    mainText.account.editAccount.form.fullName
                  }}</label>
                </div>
                <div class="col-md-9">
                  <input
                    type="text"
                    class="form-control flex-control"
                    :placeholder="mainText.account.editAccount.form.fullName"
                    v-model="data.fullName"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-3">
                  <label class="">{{
                    mainText.account.editAccount.form.username
                  }}</label>
                </div>
                <div class="col-md-9">
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="mainText.account.editAccount.form.username"
                    v-model="data.userName"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-3">
                  <label class="">{{
                    mainText.account.editAccount.form.phone
                  }}</label>
                </div>
                <div class="col-md-9">
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="mainText.account.editAccount.form.phone"
                    v-model="data.phone"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-3">
                  <label class="">{{
                    mainText.account.editAccount.form.email
                  }}</label>
                </div>
                <div class="col-md-9">
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="mainText.account.editAccount.form.email"
                    v-model="data.emailAddress"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="edit-account">
            <h3>Password Change</h3>
            <div class="form-group">
              <div class="row">
                <div class="col-md-3">
                  <label class="">Current Password</label>
                </div>
                <div class="col-md-9">
                  <input
                    type="password"
                    class="form-control"
                    placeholder="Current Password"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-3">
                  <label class="">New Password</label>
                </div>
                <div class="col-md-9">
                  <input
                    type="password"
                    class="form-control"
                    placeholder="New Password"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-3">
                  <label class="">Confirm New Password</label>
                </div>
                <div class="col-md-9">
                  <input
                    type="password"
                    class="form-control"
                    placeholder="Confirm New Password"
                  />
                </div>
              </div>
            </div>
          </div> -->
          <div class="row">
            <div class="col-md-12">
              <div class="dash-btn">
                <!-- <button
                  type="button"
                  ref="save"
                  @click="updateProfile"
                  class="main-btn btn"
                >
                  {{ mainText.save }}
                </button> -->
                <button
                  type="button"
                  ref="save"
                  @click="updateProfile"
                  class="dark-button save-button button btn main-btn"
                >
                  <i class="fas fa-spinner"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- error alert -->
    <transition name="slide-fade">
      <div class="error-alert" v-if="error.static.error">
        <span @click="error.static.error = false">
          <i class="fas fa-times"></i>
        </span>
        <p>{{ error.static.text }}</p>
      </div>
    </transition>
    <!-- success alert -->
    <transition name="slide-fade">
      <div class="success-alert" v-if="success">
        <span @click="success = false"> <i class="fas fa-times"></i> </span>
        <p>Profile Updated</p>
      </div>
    </transition>
    <!--end signin-->
  </div>
</template>

<script>
import axios from "axios";
import Loader from "../../Loading/Loading";
import textAr from "../../lang/ar.json";
import textEn from "../../lang/en.json";
export default {
  data() {
    return {
      data: {
        fullName: "",
        userName: "",
        phone: "",
        emailAddress: "",
      },
      loading: false,
      myData: "",
      error: {
        static: {
          error: false,
          text: "",
        },
      },
      success: false,
      lang: localStorage.getItem("shop_lang"),
      mainText: "",
    };
  },
  methods: {
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    getData() {
      this.loading = true;
      axios
        .get("/get-profile", {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            Accept: "application/json",
            lang: "en",
          },
        })
        .then((res) => {
          this.loading = false;
          this.myData = res.data.data;
          this.data.fullName = this.myData.full_name;
          this.data.userName = this.myData.user_name;
          this.data.phone = this.myData.phone;
          this.data.emailAddress = this.myData.email;
          console.log(res);
        });
    },
    // update profile
    updateProfile() {
      const submitButton = this.$refs["save"];
      submitButton.classList.add("click");
      this.error.errorEmail = false;
      this.error.errorPassword = false;
      this.error.static.error = false;
      if (this.data.fullName == "") {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.error.static.error = true;
          this.error.static.text = this.mainText.account.editAccount.errors.fullName;
          this.success = false;
        }, 1000);
      } else if (this.data.userName == "") {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.error.static.error = true;
          this.error.static.text = this.mainText.account.editAccount.errors.username;
          this.success = false;
        }, 1000);
      } else if (this.data.phone == "") {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.error.static.error = true;
          this.error.static.text = this.mainText.account.editAccount.errors.phone;
          this.success = false;
        }, 1000);
      } else if (this.data.emailAddress == "") {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.error.static.error = true;
          this.error.static.text = this.mainText.account.editAccount.errors.email;
          this.success = false;
        }, 1000);
      } else if (!this.validEmail(this.data.emailAddress)) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.error.static.error = true;
          this.error.static.text = this.mainText.account.editAccount.errors.emailVailid;
          this.success = false;
        }, 1000);
      } else {
        const data = new FormData();
        data.append("full_name", this.data.fullName);
        data.append("user_name", this.data.userName);
        data.append("phone", this.data.phone);
        data.append("email", this.data.emailAddress);
        axios
          .post("/edit-profile", data, {
            headers: {
              Authorization: "bearer" + localStorage.getItem("shop-token"),
              "cache-control": "no-cache",
              "Content-type": `multipart/form-data;`,
              Accept: "application/json",
              lang: "en",
            },
          })
          .then(() => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.error.static.error = false;
              this.success = true;
            }, 1000);
            setTimeout(() => {
              this.$router.push({ path: "/my-account" });
            }, 2000);
          })
          .catch((error) => {
            this.error.static.error = true;
            submitButton.classList.remove("click");
            this.waiting = false;
            this.error.errorEmail = false;
            this.error.errorPassword = false;
            this.error.static.text = error.response.data.msg;
          })
          .finally(() => (this.waiting = false));
      }
    },
  },
  mounted() {
    this.getData();
    if (this.lang == "en") {
      this.mainText = textEn;
    } else {
      this.mainText = textAr;
    }
  },
  components: {
    Loader,
  },
};
</script>
