<template>
  <div id="main-app">
    <Header></Header>
    <transition name="fade" mode="out-in">
      <router-view :key="$route.path"></router-view>
    </transition>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./main-component/Header/Header";
import Footer from "./main-component/Footer";
export default {
  components: {
    Header,
    Footer,
  },
  created() {
    var body = document.querySelector("body");
    var lang = localStorage.getItem("shop_lang");
    if (lang == "en") {
      body.classList.add("ltr");
      body.classList.remove("rtl");
    } else {
      body.classList.remove("ltr");
      body.classList.add("rtl");
    }
    if (!lang) {
      localStorage.setItem("shop_lang", "en");
    }
  },
  mounted() {
    document.getElementById("main-app").scrollIntoView({ behavior: "smooth" });
  },
};
</script>
