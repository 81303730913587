<template>
  <div>
    <MainSlider></MainSlider>
    <categories></categories>
    <!-- <AboutHome></AboutHome> -->
    <!-- <TopRating v-if="this.$store.state.homeData.top_rated != null"></TopRating> -->
    <ExclusiveProducts></ExclusiveProducts>
    <!-- <Banners v-if="this.$store.state.homeData.advertisements != null"></Banners> -->
    <BestSelling></BestSelling>
    <!-- <SiteInfo></SiteInfo> -->
    <SummerCollection></SummerCollection>
    
    
    <!-- <ClientOpinion
      v-if="this.$store.state.homeData.our_client_say != null"
    ></ClientOpinion> -->
    <Partners></Partners>
    <NewsLetter></NewsLetter>
  </div>
</template>

<script>
import MainSlider from "./MainSlider";
import categories from "./categories";
// import TopRating from "./topRating";
// import Banners from "./Banners";
import BestSelling from "./BestSelling";
// import SiteInfo from "./SiteInfo";
import ExclusiveProducts from "./ExclusiveProducts";
// import ClientOpinion from "./ClientOpinion";
import Partners from "./Partners";
import AboutHome from "./AboutHome.vue";
import SummerCollection from "./SummerCollection.vue";
import NewsLetter from "./NewsLetter.vue";

import textAr from "../lang/ar.json";
import textEn from "../lang/ar.json";
export default {
  data() {
    return {
      // splashLoader: true,
      lang: localStorage.getItem("shop_lang"),
      mainText: "",
    };
  },
  components: {
    MainSlider,
    // TopRating,
    // Banners,
    BestSelling,
    // SiteInfo,
    ExclusiveProducts,
    // ClientOpinion,
    Partners,
    AboutHome,
    SummerCollection,
    NewsLetter,
    categories,
  },
  methods: {},
  mounted() {
    // START:: GET HOME DATA
    this.$store.dispatch("getHomeData");
    // END:: GET HOME DATA
    if (this.lang == "en") {
      this.mainText = textEn;
    } else {
      this.mainText = textAr;
    }
  },
};
</script>
