<template>
  <div>
    <!--start page-banner-->

    <section class="page-banner">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <!--breadcrumbs-->
            <div class="breadcrumbs">
              <h3 v-if="lang == 'en'">Terms Of Use</h3>
              <h3 v-if="lang == 'ar'">سياسة الاستخدام</h3>
              <ul class="list-unstyled">
                <li v-if="lang == 'en'">
                  <router-link to="/">Home </router-link>>
                </li>
                <li v-if="lang == 'ar'">
                  <router-link to="/">الرئيسية </router-link>>
                </li>
                <li v-if="lang == 'en'">Terms Of Use</li>
                <li v-if="lang == 'ar'">سياسة الاستخدام</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--end page-banner-->

    <!--start shipping-->

    <section class="shipping">
      <div class="container">
        <Loader v-if="loading" class="fadeIn"></Loader>
        <div class="all-text" v-else>
          <NotFound v-if="allData == ''"></NotFound>
          <div class="row" v-else>
            <div class="col-sm-12 fadeIn">
              <p v-html="allData"></p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--end shipping-->
  </div>
</template>

<script>
import axios from "axios";
import Loader from "./../Loading/Loading";
import NotFound from "../Loading/not-found";
export default {
  data() {
    return {
      allData: "",
      loading: false,
      lang: localStorage.getItem("shop_lang"),
      mainText: "",
    };
  },

  methods: {
    getData() {
      this.loading = true;
      axios
        .get("general/settings", {
          headers: {
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          this.loading = false;
          this.allData = res.data.data.terms_use;
          console.log(this.allData);
        })
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this.getData();
  },
  components: {
    Loader,
    NotFound,
  },
};
</script>
