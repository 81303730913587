<template>
  <!-- link of fav on header -->
  <div class="favourite-icon">
    <div class="text-icons">
      <a @click="showFav()" class="btn-count">
        <div class="favourite-icons">
          <small class="myCount">
            {{ this.$store.state.favouriteList.length }}
          </small>
        </div>
      </a>
      <span v-if="lang == 'en'">WishList</span>
      <span v-else>المفضلة</span>
    </div>
    <!-- side menu of favourite items -->
    <div class="cart-menu fav-menu" :class="{ show: show }">
      <a @click="hideFav()" class="close-cart only-mobile"
        ><i class="fas fa-times"></i
      ></a>
      <div class="cart-title" v-if="mainText">
        <h4>{{ mainText.home.favourite.title }}</h4>
        <span class="cart-number"
          >{{ this.$store.state.favouriteList.length }}
          {{ mainText.home.favourite.subTitle }}</span
        >
      </div>
      <Loader v-if="loading" class="fadeIn"></Loader>
      <div class="fadeIn" v-else>
        <NotFound
          v-if="this.$store.state.favouriteList == ''"
          class="fadeIn"
        ></NotFound>
        <ul class="list-unstyled" v-else>
          <!-- cart item -->
          <li v-for="item in this.$store.state.favouriteList" :key="item.id">
            <div class="cart-item">
              <div class="cart-prod">
                <!--favourite item image-->
                <div class="cart-prod-img">
                  <img :src="item.main_image" />
                </div>
                <!--favourite item description-->
                <div class="cart-prod-tit">
                  <h4>{{ item.name }}</h4>
                  <span class="cat">{{ item.brand_name }}</span>
                  <h5>{{ item.price }} <span class="currency"></span></h5>
                </div>
              </div>
              <div class="like" v-if="token">
                <button
                  @click="
                    $set(item, 'isActive', !item.isActive), doLike(item.id)
                  "
                  v-if="item.is_favourite == false"
                  :class="[
                    'btn',
                    { likee: item.isActive },
                    { dislikee: !item.isActive },
                  ]"
                ></button>
                <!-- like -->
                <button
                  @click="
                    $set(item, 'isActive', !item.isActive), doLike(item.id)
                  "
                  v-else
                  :class="[
                    'btn',
                    { likee: !item.isActive },
                    { dislikee: item.isActive },
                  ]"
                ></button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="overlay" @click="hideFav()" :class="{ disblock: show }"></div>
  </div>
</template>

<script>
// import axios from "axios";
import Loader from "../../Loading/sideMenu";
import NotFound from "./../../Loading/not-found";
import textAr from "../../lang/ar.json";
import textEn from "../../lang/en.json";
export default {
  data() {
    return {
      show: false,
      counter: 1,
      allData: "",
      loading: false,
      token: localStorage.getItem("shop-token"),
      lang: localStorage.getItem("shop_lang"),
      mainText: "",
    };
  },
  methods: {
    showFav() {
      this.show = true;
      // if (this.show !== false) {
      //   this.getFav();
      // }
    },
    hideFav() {
      this.show = false;
    },
    counterPlus() {
      this.counter++;
    },
    counterMinus() {
      if (this.counter > 1) {
        this.counter--;
      }
    },
    // getFav() {
    //   this.loading = true;
    //   axios
    //     .get("/myFavourites", {
    //       headers: {
    //         Authorization: "bearer" + localStorage.getItem("shop-token"),
    //         "cache-control": "no-cache",
    //         "Content-type": "application/json",
    //         Accept: "application/json",
    //         lang: localStorage.getItem("shop_lang"),
    //       },
    //     })
    //     .then((res) => {
    //       this.loading = false;
    //       this.allData = res.data.data;
    //     });
    // },
    // add to favourite
    doLike(id) {
      this.$store.dispatch("addFavourite", { id });
    },
  },
  components: {
    Loader,
    NotFound,
  },
  mounted() {
    this.$store.dispatch("getFavourite");
    if (this.lang == "en") {
      this.mainText = textEn;
    } else {
      this.mainText = textAr;
    }
  },
};
</script>
