<template>
  <div>
    <div class="account-details">
      <div class="my-form">
        <form>
          <div class="edit-account">
            <h3>{{ mainText.account.addresses.addAddress.title }}</h3>
            <div class="form-group">
              <div class="row">
                <div class="col-md-3">
                  <label class="">{{
                    mainText.account.addresses.addAddress.form.addressName
                  }}</label>
                </div>
                <div class="col-md-9">
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="
                      mainText.account.addresses.addAddress.form.addressName
                    "
                    v-model="address.address_name"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-3">
                  <label class="">{{
                    mainText.account.addresses.addAddress.form.street
                  }}</label>
                </div>
                <div class="col-md-9">
                  <div class="row">
                    <div class="col-md-6">
                      <input
                        type="text"
                        class="form-control flex-control"
                        :placeholder="
                          mainText.account.addresses.addAddress.form.street1
                        "
                        v-model="address.street_one"
                      />
                    </div>
                    <div class="col-md-6">
                      <input
                        type="text"
                        class="form-control flex-control"
                        :placeholder="
                          mainText.account.addresses.addAddress.form.street
                        "
                        v-model="address.street_two"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-3">
                  <label class="">{{
                    mainText.account.addresses.addAddress.form.country
                  }}</label>
                </div>
                <div class="col-md-9">
                  <select
                    class="custom-select"
                    @change="getCities()"
                    v-model="address.country_id"
                  >
                    <option>Choose Country</option>
                    <option
                      v-for="country in countries"
                      :key="country.id"
                      :value="country.id"
                    >
                      {{ country.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-3">
                  <label class="">{{
                    mainText.account.addresses.addAddress.form.city
                  }}</label>
                </div>
                <div class="col-md-9">
                  <select
                    class="custom-select"
                    :class="{ disabled: disabledCities }"
                    v-model="address.city_id"
                  >
                    <option>Choose City</option>
                    <option
                      v-for="city in cities"
                      :key="city.id"
                      :value="city.id"
                    >
                      {{ city.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <!-- <div class="form-group">
              <div class="row">
                <div class="col-md-3">
                  <label class="">State</label>
                </div>
                <div class="col-md-9">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Town / City"
                  />
                </div>
              </div>
            </div> -->
            <div class="form-group">
              <div class="row">
                <div class="col-md-3">
                  <label class="">{{
                    mainText.account.addresses.addAddress.form.zipCode
                  }}</label>
                </div>
                <div class="col-md-9">
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="
                      mainText.account.addresses.addAddress.form.zipCode
                    "
                    v-model="address.zip_code"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="dash-btn">
                <!-- <button
                  type="button"
                  @click="addAddress"
                  ref="add"
                  class="main-btn btn"
                >
                  {{ mainText.account.addresses.addAddress.form.saveAddress }}
                </button> -->
                <button
                  type="button"
                  ref="add"
                  @click="addAddress"
                  class="dark-button add-button button btn main-btn"
                >
                  <i class="fas fa-spinner"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- error alert -->
    <transition name="slide-fade">
      <div class="error-alert" v-if="error.static.error">
        <span @click="error.static.error = false">
          <i class="fas fa-times"></i>
        </span>
        <p>{{ error.static.text }}</p>
      </div>
    </transition>
    <!-- success alert -->
    <transition name="slide-fade">
      <div class="success-alert" v-if="success">
        <span @click="success = false"> <i class="fas fa-times"></i> </span>
        <p>{{ mainText.account.addresses.addAddress.form.successAdd }}</p>
      </div>
    </transition>
    <!--end signin-->
  </div>
</template>

<script>
import axios from "axios";
import textAr from "../../lang/ar.json";
import textEn from "../../lang/en.json";
export default {
  data() {
    return {
      countries: "",
      disabledCities: true,
      cities: "",
      address: {
        address_name: "",
        street_one: "",
        street_two: "",
        country_id: "",
        city_id: "",
        zip_code: "",
      },
      error: {
        static: {
          error: false,
          text: "",
        },
      },
      success: false,
      lang: localStorage.getItem("shop_lang"),
      mainText: "",
    };
  },
  methods: {
    // get countries
    getCountries() {
      // loading
      this.loading = true;
      axios
        .get("/general/get-countries", {
          headers: {
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            lang: "en",
          },
        })
        .then((res) => {
          this.loading = false;
          this.countries = res.data.data;
        })
        .finally(() => (this.loading = false));
    },
    // get cities
    getCities() {
      axios
        .get("/general/get-cities/" + this.address.country_id, {
          headers: {
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            lang: "en",
          },
        })
        .then((res) => {
          (this.disabledCities = false), (this.cities = res.data.data);
        })
        .finally(() => (this.loading = false));
    },
    addAddress() {
      const submitButton = this.$refs["add"];
      submitButton.classList.add("click");
      this.error.static.error = false;
      if (this.address.address_name == "") {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.error.static.error = true;
          this.error.static.text = this.mainText.account.addresses.addAddress.error.addressName;
          this.success = false;
        }, 1000);
      } else if (this.address.street_one == "") {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.error.static.error = true;
          this.error.static.text = this.mainText.account.addresses.addAddress.error.street1;
          this.success = false;
        }, 1000);
      } else if (this.address.street_two == "") {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.error.static.error = true;
          this.error.static.text = this.mainText.account.addresses.addAddress.error.street2;
          this.success = false;
        }, 1000);
      } else if (this.address.country_id == "") {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.error.static.error = true;
          this.error.static.text = this.mainText.account.addresses.addAddress.error.country;
          this.success = false;
        }, 1000);
      } else if (this.address.city_id == "") {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.error.static.error = true;
          this.error.static.text = this.mainText.account.addresses.addAddress.error.city;
          this.success = false;
        }, 1000);
      } else if (this.address.zip_code == "") {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.error.static.error = true;
          this.error.static.text = this.mainText.account.addresses.addAddress.error.zipCode;
          this.success = false;
        }, 1000);
      } else {
        const data = new FormData();
        data.append("address_name", this.address.address_name);
        data.append("street_one", this.address.street_one);
        data.append("street_two", this.address.street_two);
        data.append("country_id", this.address.country_id);
        data.append("city_id", this.address.city_id);
        data.append("zip_code", this.address.zip_code);
        axios
          .post("/addresses", data, {
            headers: {
              Authorization: "bearer" + localStorage.getItem("shop-token"),
              "cache-control": "no-cache",
              "Content-type": `multipart/form-data;`,
              Accept: "application/json",
              lang: localStorage.getItem("shop_lang"),
            },
          })
          .then(() => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.error.static.error = false;
              this.success = true;
            }, 1000);
            setTimeout(() => {
              this.$router.push({ path: "/addresses" });
            }, 2000);
          })
          .catch((error) => {
            this.error.static.error = true;
            submitButton.classList.remove("click");
            this.waiting = false;
            this.error.static.text = error.response.data.msg;
          })
          .finally(() => (this.waiting = false));
      }
    },
  },
  mounted() {
    this.getCountries();
    if (this.lang == "en") {
      this.mainText = textEn;
    } else {
      this.mainText = textAr;
    }
  },
};
</script>
