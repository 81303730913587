<template>
  <div>
    <!--start page-banner-->

    <section class="page-banner" :style="'background-image: url('+allData.about_background+')'">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <!--breadcrumbs-->
            <div class="breadcrumbs">
              <h3>{{ mainText.pagesTitle.about }}</h3>
              <ul class="list-unstyled">
                <li>
                  <router-link to="/">{{ mainText.home.title }} </router-link>>
                </li>
                <li>{{ mainText.pagesTitle.about }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--end page-banner-->
    <!--strat about-us-->
    <section class="about-us">
      <Loader v-if="loading"></Loader>
      <div class="container" v-else>
        <NotFound v-if="allData == ''"></NotFound>
        <div class="row" v-else>
          <div class="col-sm-12">
            <div class="our-company">
              <div class="main-title-bold">
                <!-- <h3>Our Company</h3> -->
                <p v-html="allData.about"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--end about-us-->

    <!--site info-->
    <SiteInfo></SiteInfo>
    <!--site info-->
    <!--partner-->
    <Partners></Partners>
    <!--partner-->
  </div>
</template>

<script>
import SiteInfo from "./../home/SiteInfo";
import Partners from "./../home/Partners";
import axios from "axios";
import textAr from "../lang/ar.json";
import textEn from "../lang/en.json";
import NotFound from "../Loading/not-found";
import Loader from "./../Loading/Loading";
export default {
  data() {
    return {
      allData: "",
      id: this.$route.params.id,
      loading: false,
      lang: localStorage.getItem("shop_lang"),
      mainText: "",
    };
  },
  components: {
    SiteInfo,
    Partners,
    NotFound,
    Loader,
  },

  methods: {
    getData() {
      this.loading = true;
      axios
        .get("general/settings", {
          headers: {
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          this.loading = false;
          this.allData = res.data.data;
        })
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this.c1 = this.$refs.c1;
    this.c2 = this.$refs.c2;
    this.getData();
    if (this.lang == "en") {
      this.mainText = textEn;
    } else {
      this.mainText = textAr;
    }
  },
};
</script>
