<template>
  <div class="left-head">
    <div class="logo">
      <!-- <button type="button" class="btn" @click="showMenu()">
        <div class="more-icon"></div>
      </button> -->
      <router-link to="/" tag="a">
        <img src="@/assets/images/logo.png" />
      </router-link>
    </div>
    <div class="nav-menu" :class="{ show: show }">
      <Loader v-if="loading"></Loader>
      <div v-else class="all-categories">
        <div
          class="single-category"
          v-for="category in allData"
          :key="category.id"
          :style="'background-image: url(' + category.image + ');'"
        >
          <h3 class="title-category">
            <i class="fas fa-box"></i>  {{ category.name }}
          </h3>

          <ul class="sub-category">
              <li @click="hideMenu()">
                <router-link v-if="lang == 'en' " :to="'/category/' + category.id">  <i class="fas fa-circle"></i> All Products</router-link>
                <router-link v-else :to="'/category/' + category.id"> كل المنتجات  <i class="fas fa-circle"></i> </router-link>
              </li>
              <li
                v-for="subCategory in category.sub_cats"
                :key="subCategory.id"
                @click="hideMenu()"
              >
                <router-link
                  :to="
                    '/category/' +
                    category.id +
                    '/sub-category/' +
                    subCategory.id
                  "
                  ><i class="fas fa-circle"></i> {{ subCategory.name }}</router-link
                >
              </li>
            </ul>
        </div>
      </div>

      <!-- menu button -->
      <ul class="main-menu-header main-menu-mobile">
        <li class="nav-item">
          <router-link to="/" class="nav-link" v-if="lang == 'en'"
            >Home</router-link
          >
          <router-link to="/" class="nav-link" v-else>الرئيسة</router-link>
        </li>
        <!-- link on sub menu -->
        <li class="nav-item" @click="hideMenu()">
          <router-link to="/about-us" class="nav-link" v-if="lang == 'en'"
            >About Us</router-link
          >
          <router-link to="/about-us" class="nav-link" v-else
            >من نحن</router-link
          >
        </li>
        <li class="nav-item" @click="hideMenu()">
          <router-link to="/contact-us" class="nav-link" v-if="lang == 'en'"
            >Contact Us</router-link
          >
          <router-link to="/contact-us" class="nav-link" v-else
            >تواصل معنا</router-link
          >
        </li>
      </ul>

      <!-- link on sub menu -->
      <ul class="list-unstyled social-icon mobile-social-icon">
        <li>
          <a :href="social.facebook_url"><i class="fab fa-facebook-f"></i></a>
        </li>
        <li>
          <a :href="social.instagram_url"><i class="fab fa-instagram"></i></a>
        </li>
        <li>
          <a :href="social.twitter_url"><i class="fab fa-tiktok"></i></a>
        </li>
      </ul>
    </div>
    <!-- logo on header -->
    <div class="overlay" @click="hideMenu()" :class="{ disblock: show }"></div>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "./../../Loading/sideMenu";
export default {
  name: "LogoSite",
  data() {
    return {
      show: false,
      loading: false,
      allData: "",
      social: "",
      lang: localStorage.getItem("shop_lang"),
    };
  },
  methods: {
    showMenu() {
      this.show = true;
      this.getData();
      this.getSocial();
    },
    hideMenu() {
      this.show = false;
    },
    getData() {
      this.loading = true;
      axios
        .get("/general/all-categories", {
          headers: {
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          this.loading = false;
          this.allData = res.data.data;
        })
        .finally(() => (this.loading = false));
    },
    getSocial() {
      this.loading = true;
      axios
        .get("/general/settings", {
          headers: {
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          this.loading = false;
          this.social = res.data.data.contact_us;
        })
        .finally(() => (this.loading = false));
    },
  },
  components: {
    Loader,
  },
};
</script>
