<template>
  <div>
    <!--start signin-->

    <div class="signin special-padding forget-password">
      <div class="container">
        <div class="row justify-content-center align-self-center">
          <div class="col-md-6">
            <div class="sign-form">
              <div class="logo">
                <router-link to="/" tag="a">
                  <img class="img-responsive" src="@/assets/images/logo.png" />
                </router-link>
                <p v-if="lang == 'en'">Verification code</p>
                <p v-if="lang == 'ar'">كود التحقق</p>
                <span v-if="lang == 'en'"
                  >Please Enter The Code That Sent To Your Email Address
                </span>
                <span v-if="lang == 'ar'"
                  >يرجى ادخال الكود الذى تم ارسالة الى بريدك الالكتروني
                </span>
              </div>
              <form>
                <div class="form-group d-flex" dir="ltr">
                  <input
                    type="text"
                    maxlength="1"
                    class="form-control text-center mobile-verify"
                    placeholder=" __ "
                    v-model="oneNum"
                  />
                  <input
                    type="text"
                    maxlength="1"
                    class="form-control text-center mobile-verify"
                    placeholder=" __ "
                    v-model="twoNum"
                  />
                  <input
                    type="text"
                    maxlength="1"
                    class="form-control text-center mobile-verify"
                    placeholder=" __ "
                    v-model="threeNum"
                  />
                  <input
                    type="text"
                    maxlength="1"
                    class="form-control text-center mobile-verify"
                    placeholder=" __ "
                    v-model="fourNum"
                  />
                  <input
                    type="text"
                    maxlength="1"
                    class="form-control text-center mobile-verify"
                    placeholder=" __"
                    v-model="fiveNum"
                  />
                  <input
                    type="text"
                    maxlength="1"
                    class="form-control text-center mobile-verify"
                    placeholder=" __"
                    v-model="sixNum"
                    v-on:keydown.enter.prevent="codeEnter"
                  />
                </div>
                <div class="bottom-sign">
                  <button
                    type="button"
                    ref="forget"
                    @click="codeLogin"
                    class="dark-button forget-button button main-btn"
                  >
                    <i class="fas fa-spinner"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="shape">
        <img src="@/assets/images/shapes/shape2.png" />
      </div>
    </div>
    <!--end signin-->
    <!-- error alert -->
    <transition name="slide-fade">
      <div class="error-alert" v-if="error.static.error">
        <span @click="error.static.error = false">
          <i class="fas fa-times"></i>
        </span>
        <p>{{ error.static.text }}</p>
      </div>
    </transition>
    <!-- success alert -->
    <transition name="slide-fade">
      <div class="success-alert" v-if="success">
        <span @click="success = false"> <i class="fas fa-times"></i> </span>
        <p>successfull code</p>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import textAr from "../../lang/ar.json";
import textEn from "../../lang/en.json";
export default {
  name: "codeVerify",
  data() {
    return {
      oneNum: "",
      twoNum: "",
      threeNum: "",
      fourNum: "",
      fiveNum: "",
      sixNum: "",
      email: localStorage.getItem("shop-email"),
      error: {
        static: {
          error: false,
          text: "",
        },
      },
      success_code: localStorage.getItem("shop-code"),
      code: "",
      success: false,
      lang: localStorage.getItem("shop_lang"),
      mainText: "",
    };
  },
  mounted() {
    var userType = localStorage.getItem("shop-token");
    if (userType) {
      this.$router.push({ path: "/" });
    }
    this.searchData();
    if (this.lang == "en") {
      this.mainText = textEn;
    } else {
      this.mainText = textAr;
    }
  },
  methods: {
    codeLogin() {
      const submitButton = this.$refs["forget"];
      submitButton.classList.add("click");
      this.error.errorEmail = false;
      this.error.errorPassword = false;
      this.error.static.error = false;
      this.code =
        this.oneNum +
        this.twoNum +
        this.threeNum +
        this.fourNum +
        this.fiveNum +
        this.sixNum;
      if (this.success_code !== this.code) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.error.static.error = true;
          this.error.static.text = "code is invalid";
          this.success = false;
        }, 1000);
      } else {
        const data = new FormData();
        data.append("email", this.email);
        data.append("verification_code", this.code);
        data.append("device_token", "dddd");
        data.append("device_type", "web");
        axios
          .post("/auth/email/verify", data, {
            headers: {
              "cache-control": "no-cache",
              "Content-type": `multipart/form-data;`,
              Accept: "application/json",
              lang: "en",
            },
          })
          .then((res) => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.error.static.error = false;
              this.success = true;
              localStorage.setItem("shop-token", res.data.data.token);
              localStorage.removeItem("shop-email");
              localStorage.removeItem("shop-code");
            }, 1000);
            setTimeout(() => {
              this.$router.push({ path: "/" });
            }, 2000);
          })
          .catch((error) => {
            this.error.static.error = true;
            submitButton.classList.remove("click");
            this.waiting = false;
            this.error.static.text = error.response.data.msg;
          })
          .finally(() => (this.waiting = false));
      }
    },
    codeEnter() {
      if (event.key == "Enter") {
        this.codeLogin();
      }
    },
    searchData() {
      var allElements = document.querySelectorAll(".mobile-verify");
      allElements[0].focus();
      for (var i = 0; i < allElements.length; i++) {
        var el = allElements[i];
        el.addEventListener("keypress", function () {
          this.nextSibling.focus();
        });
      }
    },
  },
  components: {},
};
</script>
