<template>
  <!-- loader -->
  <div class="container">
    <div class="row cards">
      <div class="col-lg-3">
        <div class="card-loader">
          <div class="animated-background profile-image"></div>
          <div class="small">
            <div class="animated-background prod-name-load"></div>
            <div class="animated-background pro-rate-load"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="card-loader">
          <div class="animated-background profile-image"></div>
          <div class="small">
            <div class="animated-background prod-name-load"></div>
            <div class="animated-background pro-rate-load"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="card-loader">
          <div class="animated-background profile-image"></div>
          <div class="small">
            <div class="animated-background prod-name-load"></div>
            <div class="animated-background pro-rate-load"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="card-loader">
          <div class="animated-background profile-image"></div>
          <div class="small">
            <div class="animated-background prod-name-load"></div>
            <div class="animated-background pro-rate-load"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {},
  components: {},
};
</script>
<style scoped>
.cards .card-loader .small {
  display: block;
  text-align: right;
}
.cards .profile-image {
  margin-top: 10px;
  width: 100%;
  height: 250px;
}
.cards .animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholder;
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(to right, #eee 8%, #ddd 18%, #eee 33%);
  background-size: 1200px 100px;
  min-height: 50px;
  width: 100%;
  margin: 0;
  border-radius: 3px;
}
.cards .small .animated-background {
  min-height: 15px;
  margin: 10px 0;
  width: 45%;
}
.cards .small .animated-background:first-of-type {
  width: 80%;
}
@keyframes placeholder {
  0% {
    background-position: -600px 0;
  }
  100% {
    background-position: 600px 0;
  }
}
</style>
