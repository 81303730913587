<template>
  <div>
    <!--start page-banner-->

    <section class="page-banner" :style="'background-image: url('+terms_background+')'">
      <div class="container">
        <div class="row fadeIn">
          <div class="col-sm-12">
            <!--breadcrumbs-->
            <div class="breadcrumbs">
              <h3>{{ mainText.pagesTitle.condition }}</h3>
              <ul class="list-unstyled">
                <li>
                  <router-link to="/">{{ mainText.home.title }} </router-link>>
                </li>
                <li>{{ mainText.pagesTitle.condition }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--end page-banner-->

    <!--start shipping-->

    <section class="shipping">
      <div class="container">
        <Loader v-if="loading" class="fadeIn"></Loader>
        <div class="all-text" v-else>
          <NotFound v-if="allData == ''"></NotFound>
          <div class="row" v-else>
            <!-- <div class="col-lg-12 fadeIn" v-if="loading">
              <Loader class="fadeIn"></Loader>
            </div> -->
            <div class="col-sm-12 fadeIn">
              <p v-html="allData"></p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--end shipping-->
  </div>
</template>

<script>
import axios from "axios";
import Loader from "./../Loading/Loading";
import textAr from "../lang/ar.json";
import textEn from "../lang/en.json";
import NotFound from "../Loading/not-found";
export default {
  data() {
    return {
      allData: "",
      loading: false,
      lang: localStorage.getItem("shop_lang"),
      mainText: "",
      found: true,
      terms_background: "",
    };
  },

  methods: {
    getData() {
      this.loading = true;
      this.found = false;
      axios
        .get("general/settings", {
          headers: {
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          this.loading = false;
          setTimeout(() => {
            this.found = true;
          });
          this.allData = res.data.data.conditions_terms;
          this.terms_background = res.data.data.terms_background;
        })
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this.getData();
    if (this.lang == "en") {
      this.mainText = textEn;
    } else {
      this.mainText = textAr;
    }
  },
  components: {
    Loader,
    NotFound,
  },
};
</script>
