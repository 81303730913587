<template>
  <!--start site-info-->

  <section class="site-info" v-if="mainText">
    <div class="container">
      <div class="row">
        <div class="col-lg col-6 col-md-4">
          <div class="some-info">
            <div class="image">
              <img src="@/assets/images/store2-home-icon-5.png" />
            </div>
            <div class="info-exp">
              <p>{{ mainText.home.siteInfo.freeReturn }}</p>
            </div>
          </div>
        </div>
        <div class="col-lg col-6 col-md-4">
          <div class="some-info">
            <div class="image">
              <img src="@/assets/images/store2-home-icon-3.png" />
            </div>
            <div class="info-exp">
              <p>{{ mainText.home.siteInfo.wayPay }}</p>
            </div>
          </div>
        </div>
        <div class="col-lg col-6 col-md-4">
          <div class="some-info">
            <div class="image">
              <img src="@/assets/images/store2-home-icon-2.png" />
            </div>
            <div class="info-exp">
              <p>{{ mainText.home.siteInfo.exSupport }}</p>
            </div>
          </div>
        </div>
        <div class="col-lg col-6 col-md-4">
          <div class="some-info">
            <div class="image">
              <img src="@/assets/images/store2-home-icon-1.png" />
            </div>
            <div class="info-exp">
              <p>{{ mainText.home.siteInfo.SatGuarantee }}</p>
            </div>
          </div>
        </div>
        <div class="col-lg col-md-4">
          <div class="some-info">
            <div class="image">
              <img src="@/assets/images/store2-home-icon-4.png" />
            </div>
            <div class="info-exp">
              <p>{{ mainText.home.siteInfo.FastDelivery }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--end site-info-->
</template>

<script>
import textAr from "../lang/ar.json";
import textEn from "../lang/en.json";
export default {
  data() {
    return {
      lang: localStorage.getItem("shop_lang"),
      mainText: "",
    };
  },
  mounted() {
    if (this.lang == "en") {
      this.mainText = textEn;
    } else {
      this.mainText = textAr;
    }
  },
};
</script>
