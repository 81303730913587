<template>
  <div>
    <Header></Header>
    <!--start page-banner-->
    <section class="page-banner">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <!--breadcrumbs-->
            <div class="breadcrumbs">
              <h3>{{ mainText.account.breadcrumbs.title }}</h3>
              <ul class="list-unstyled">
                <li>
                  <router-link to="/"
                    >{{ mainText.account.breadcrumbs.home }} </router-link
                  >>
                </li>
                <li>{{ mainText.account.breadcrumbs.title }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--end page-banner-->

    <!--start dashboard-->

    <section class="dashboard">
      <div class="container">
        <div class="row">
          <div class="col-sm-3">
            <div class="left-dash">
              <ul class="list-unstyled">
                <li class="">
                  <router-link to="/my-account">{{
                    mainText.account.menu.dashboard
                  }}</router-link>
                </li>
                <li class="">
                  <router-link to="/account-details">{{
                    mainText.account.menu.accountDetails
                  }}</router-link>
                </li>
                <li class="">
                  <router-link to="/orders">{{
                    mainText.account.menu.orders
                  }}</router-link>
                </li>
                <li class="">
                  <router-link to="/addresses">{{
                    mainText.account.menu.addresses
                  }}</router-link>
                </li>
                <!-- <li class="">
                  <router-link to="/cards">Cards</router-link>
                </li> -->
                <li class="">
                  <button type="button" class="btn" @click="Logout">
                    {{ mainText.account.menu.logout }}
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-sm-9">
            <div class="right-dash">
              <transition name="fade" mode="out-in">
                <router-view :key="$route.path" />
              </transition>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--end dashboard-->
    <Footer></Footer>
  </div>
</template>

<script>
import axios from "axios";
import Header from "./../main-component/Header/Header";
import Footer from "./../main-component/Footer";
import textAr from "../lang/ar.json";
import textEn from "../lang/en.json";
export default {
  data() {
    return {
      lang: localStorage.getItem("shop_lang"),
      mainText: "",
    };
  },
  components: {
    Header,
    Footer,
  },
  methods: {
    Logout() {
      axios
        .post("/auth/logout", [], {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then(() => {
          localStorage.removeItem("shop-token");
          setTimeout(() => {
            this.$router.push({ path: "/login" });
          }, 700);
        });
    },
  },
  mounted() {
    var userType = localStorage.getItem("shop-token");
    if (!userType) {
      this.$router.push({ path: "/login" });
    }
    if (this.lang == "en") {
      this.mainText = textEn;
    } else {
      this.mainText = textAr;
    }
  },
};
</script>
