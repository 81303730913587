<template>
  <div class="">
    <div class="main-menu">
      <!-- <button type="button" @click="showMenu()" class="menu-link btn">
        <div class="more-icon"></div>
      </button> -->
      <!-- menu button -->
      <ul class="main-menu-header">
        <li class="nav-item">
          <router-link to="/" class="nav-link" v-if="lang == 'en'"
            >Home</router-link
          >
          <router-link to="/" class="nav-link" v-else>الرئيسة</router-link>
        </li>

        <!-- link on sub menu -->
        <!-- <li class="nav-item">
          <button
            type="button"
            class="nav-link"
            @click="showMenu()"
            v-if="lang == 'en'"
          >
            Shop
          </button>
          <button type="button" class="nav-link" @click="showMenu()" v-else>
            المتجر
          </button>
        </li> -->

        <!-- link on sub menu -->
        <li class="nav-item" @click="hideMenu()">
          <router-link to="/about-us" class="nav-link" v-if="lang == 'en'"
            >About Us</router-link
          >
          <router-link to="/about-us" class="nav-link" v-else
            >من نحن</router-link
          >
        </li>
        
        <!-- link on sub menu -->
        <li class="nav-item" @click="hideMenu()">
          <router-link to="/contact-us" class="nav-link" v-if="lang == 'en'"
            >Contact Us</router-link
          >
          <router-link to="/contact-us" class="nav-link" v-else
            >تواصل معنا</router-link
          >
        </li>
      </ul>

      
      
    </div>
    <!-- logo on header -->
    <div class="overlay" @click="hideMenu()" :class="{ disblock: show }"></div>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "./../../Loading/sideMenu";
export default {
  data() {
    return {
      show: false,
      loading: false,
      allData: "",
      social: "",
      lang: localStorage.getItem("shop_lang"),
    };
  },
  methods: {
    showMenu() {
      this.show = true;
      this.getData();
      this.getSocial();
    },
    hideMenu() {
      this.show = false;
    },
    getData() {
      this.loading = true;
      axios
        .get("/general/all-categories", {
          headers: {
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          this.loading = false;
          this.allData = res.data.data;
        })
        .finally(() => (this.loading = false));
    },
    getSocial() {
      this.loading = true;
      axios
        .get("/general/settings", {
          headers: {
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          this.loading = false;
          this.social = res.data.data.contact_us;
        })
        .finally(() => (this.loading = false));
    },
  },
  components: {
    Loader,
  },
};
</script>
