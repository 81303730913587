<template>
  <div>
    <!--start page-banner-->

    <section class="page-banner">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <!--breadcrumbs-->
            <div class="breadcrumbs">
              <h3>{{ mainText.payment.title }}</h3>
              <ul class="list-unstyled">
                <li>
                  <router-link to="/"
                    >{{ mainText.account.breadcrumbs.home }} </router-link
                  >>
                </li>
                <li>{{ mainText.payment.title }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--end page-banner-->
    <loader v-if="loading" class="fadeIn"></loader>
    <div class="checkout fadeIn" v-else>
      <div class="wizard-section">
        <div class="container">
          <div class="row no-gutters">
            <div class="col-lg-12 col-md-12">
              <div class="form-wizard">
                <form class="form-horizontal">
                  <div class="form-wizard-header">
                    <p v-if="lang == 'en'">Payment Information</p>
                    <p v-if="lang == 'ar'">معلومات الدفع</p>
                    <ul class="list-unstyled form-wizard-steps clearfix">
                      <li class="active"><span></span></li>
                      <li :class="[step == 2 ? 'active' : '']">
                        <span></span>
                      </li>
                      <!-- <li><span></span></li> -->
                    </ul>
                  </div>
                  <fieldset v-if="step == 1" class="fadeIn">
                    <!-- <NotFound v-if="addresses !== ''"></NotFound> -->
                    <div class="checkout-addresses">
                      <h3 class="subtitle" v-if="lang == 'en'">
                        please choose your shipping address
                      </h3>
                      <h3 class="subtitle" v-else>يرجي إختيار عنوان التوصيل</h3>
                      <div class="spe-not-found fadeIn" v-if="addresses == ''">
                        <span class="icon">
                          <i class="fas fa-exclamation-circle"></i>
                        </span>
                        <h3 v-if="lang == 'en'">
                          Sorry There Is Address Found Here
                        </h3>
                        <h3 v-if="lang == 'ar'">
                          عفوا لم يتم ايجاد عنوان التوصيل
                        </h3>
                      </div>
                      <div class="row" v-else>
                        <div
                          class="col-md-6"
                          v-for="addres in addresses"
                          :key="addres.id"
                        >
                          <div class="choose-address">
                            <input
                              type="radio"
                              :id="'radio' + addres.id"
                              name="e"
                              @click="
                                selectAddress(addres.id, addres.address_name)
                              "
                            />
                            <label :for="'radio' + addres.id" class="lab-add">
                              <div class="address-card">
                                <h4>{{ addres.address_name }}</h4>
                                <p>
                                  {{ addres.street_one }},
                                  {{ addres.street_two }}
                                </p>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="buttons-form">
                        <router-link
                          to="/add-address"
                          class="btn main-btn"
                          v-if="addresses == ''"
                        >
                          {{ mainText.add }}
                        </router-link>
                        <!-- <button
                          @click.prevent="next()"
                          class="btn main-btn"
                          v-else
                        >
                          {{ mainText.next }}
                        </button> -->
                        <button
                          type="button"
                          v-else
                          ref="next"
                          @click.prevent="next()"
                          class="dark-button forget-button button btn main-btn"
                        >
                          <i class="fas fa-spinner"></i>
                        </button>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset v-if="step == 2" class="fadeIn">
                    <div class="check-last">
                      <div class="top-products">
                        <div
                          class="sin-prod"
                          v-for="item in allData"
                          :key="item.id"
                        >
                          <div class="sin-img">
                            <img :src="item.product_image" />
                          </div>
                          <div class="sin-det">
                            <h5>{{ item.product_name }}</h5>
                            <span class="cat">{{ item.category_name }}</span>
                            <div class="price">
                              <span class="new">{{ item.product_price }}</span>
                              <span class="currency"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="choose-add-pay">
                        <div class="choose-add notice-add">
                          <h5 v-if="lang == 'en'">Notice :</h5>
                          <h5 v-else>ملاحظة :</h5>
                          <p v-if="lang == 'en'">Cash on Delivery</p>
                          <p v-else>الدفع عند الاستلام</p>
                        </div>
                      </div>
                      <div class="middle-details">
                        <div class="mid-flex">
                          <span>{{
                            mainText.home.cart.billDetails.subtotal
                          }}</span>
                          <h4>
                            {{ otherData.sub_total_price }}
                            <span class="currency"></span>
                          </h4>
                        </div>
                        <div class="mid-flex">
                          <span>{{
                            mainText.home.cart.billDetails.discount
                          }}</span>
                          <h4>
                            {{ otherData.discount }}
                            <span class="currency"></span>
                          </h4>
                        </div>
                        <div class="mid-flex">
                          <span>{{
                            mainText.home.cart.billDetails.shipping
                          }}</span>
                          <h4>
                            {{ otherData.shipping_price }}
                            <span class="currency"></span>
                          </h4>
                        </div>
                        <div class="mid-flex">
                          <span class="tot">{{
                            mainText.home.cart.billDetails.total
                          }}</span>
                          <h4>
                            {{ otherData.final_price }}
                            <span class="currency"></span>
                          </h4>
                        </div>
                      </div>
                      <div class="cart-copoun">
                        <div class="choose-add-pay">
                          <h5 v-if="lang == 'en'">add Copoun code</h5>
                          <h5 v-else>إضافة كوبون خصم</h5>
                        </div>
                        <div class="copoun-text">
                          <input
                            class="form-control"
                            type="text"
                            v-model="copounVal"
                            @focus="error.static.error = false"
                            :placeholder="mainText.home.cart.copoun"
                          />
                          <!-- <button
                            type="button"
                            @click="copounCheck()"
                            class="apply-btn"
                            v-if="lang == 'en'"
                          >Check code
                          </button>
                          <button
                            type="button"
                            @click="copounCheck()"
                            class="apply-btn"
                            v-else
                          >فحص الكود
                          </button> -->
                        </div>
                      </div>
                      <div class="choose-add-pay">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="choose-add">
                              <h5 v-if="lang == 'en'">Shipping Address</h5>
                              <h5 v-else>عنوان التوصيل</h5>
                              <div class="address-card">
                                <h4>{{ myAddress.address_name }}</h4>
                                <p>
                                  {{ myAddress.street_one }},
                                  {{ myAddress.street_two }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="buttons-form">
                      <button @click.prevent="prev()" class="btn main-btn">
                        {{ mainText.prev }}
                      </button>
                      <button
                        type="button"
                        to="/order-accept"
                        class="dark-button check-button button btn main-btn"
                        @click="sendOrder()"
                        ref="update"
                      >
                        <i class="fas fa-spinner"></i>
                      </button>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- success alert -->
    <transition name="slide-fade">
      <div class="success-alert" v-if="success">
        <span @click="success = false"> <i class="fas fa-times"></i> </span>
        <p v-if="lang == 'ar'">
          تم تحديد {{ placeName }} كعنوان لتوصيل الطلبات
        </p>
        <p v-if="lang == 'en'">you Choose {{ placeName }} As Deliver Address</p>
      </div>
      <div class="success-alert" v-if="successDone">
        <span @click="successDone = false"> <i class="fas fa-times"></i> </span>
        <p v-if="lang == 'ar'">تم طلب الأوردر بنجاح</p>
        <p v-if="lang == 'en'">successfully order</p>
      </div>
    </transition>
    <transition name="slide-fade">
      <div class="error-alert" v-if="error.static.error">
        <span @click="error.static.error = false">
          <i class="fas fa-times"></i>
        </span>
        <p>{{ error.static.text }}</p>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import textAr from "../lang/ar.json";
import textEn from "../lang/en.json";
import loader from "../Loading/Loading";
// import NotFound from "../Loading/not-found";
export default {
  data() {
    return {
      loading: false,
      step: 1,
      email: "",
      pwd: "",
      fname: "",
      lname: "",
      mobno: "",
      address: "",
      addresses: "",
      myAddress: "",
      alertShow: false,
      success: false,
      successDone: false,
      placeName: "",
      placeId: "",
      allData: "",
      otherData: "",
      copounVal: "",
      error: {
        static: {
          error: false,
          text: "",
        },
      },
      lang: localStorage.getItem("shop_lang"),
      mainText: "",
    };
  },
  methods: {
    getCart() {
      this.loading = true;
      axios
        .get("/carts", {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          this.allData = res.data.data;
          this.otherData = res.data;
          this.loading = false;
        });
    },
    getAddresses() {
      axios
        .get("/addresses", {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          this.addresses = res.data.data;
        });
    },
    // select address
    selectAddress(id, name) {
      this.laoding = true;
      const data = new FormData();
      data.append("address_id", id);
      axios
        .post("/select/address", data, {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            "Content-type": `multipart/form-data;`,
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then(() => {
          this.laoding = false;
          this.placeName = name;
          this.placeId = id;
          this.success = true;
          this.error.static.error = false;
          this.get_selected_address();
          setTimeout(() => {
            this.success = false;
          }, 1000);
        });
    },

    //get selected address
    get_selected_address() {
      axios
        .get("/addresses/" + this.placeId, {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            "Content-type": `multipart/form-data;`,
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          this.myAddress = res.data.data;
        });
    },
    // ----------------------------------------------------------------
    // send order
    orderMethos() {
      const submitButton = this.$refs["update"];
      submitButton.classList.add("click");
      const data = new FormData();
      data.append("address_id", this.placeId);
      data.append("payment_method", "cash");
      if (this.copounVal != "") {
        data.append("coupon_code", this.copounVal);
      }
      axios
        .post("/create-order", data, {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            lang: localStorage.getItem("shop_lang"),
            "cache-control": "no-cache",
            "Content-type": `multipart/form-data;`,
            Accept: "application/json",
          },
        })
        .then(() => {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.successDone = true;
            const cartNumber = document.getElementById("number-cart");
            cartNumber.innerHTML = "0";
          }, 1000);
          setTimeout(() => {
            this.$router.push({ path: "/orders" });
          }, 2000);
        })
        .catch((error) => {
          this.error.static.error = true;
          this.error.static.text = error.response.data.msg;
        });
    },
    sendOrder() {
      if (this.copounVal != "") {
        this.copounCheck();
      } else {
        this.orderMethos();
      }
    },
    copounCheck() {
      if (this.copounVal == "") {
        this.error.static.error = true;
        if (this.lang == "en") {
          this.error.static.text = "please enter your copoun";
        } else {
          this.error.static.text = "يرجي إدخال كود كوبون الخصم";
        }
      } else {
        const data = new FormData();
        data.append("coupon_code", this.copounVal);
        axios
          .post("/check-coupon", data, {
            headers: {
              Authorization: "bearer" + localStorage.getItem("shop-token"),
              "cache-control": "no-cache",
              "Content-type": "application/json",
              Accept: "application/json",
              lang: localStorage.getItem("shop_lang"),
            },
          })
          .then((res) => {
            this.orderMethos();
            this.allData = res.data.data;
            this.otherData = res.data;
            this.error.static.error = false;
          })
          .catch((error) => {
            this.error.static.error = true;
            this.error.static.text = error.response.data.msg;
          });
      }
    },
    // ----------------------------------------------------------------
    prev() {
      this.step--;
    },
    next() {
      const submitButton = this.$refs["next"];
      submitButton.classList.add("click");
      if (this.placeId != "") {
        this.step++;
      } else {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.error.static.error = true;
          if (this.lang == "ar") {
            this.error.static.text = "يرجى اختيار عنوان التوصيل اولا";
          } else {
            this.error.static.text = "Please Choose DELIVER Address First";
          }
        }, 1000);
      }
    },
    submit() {
      alert("Form Is Submitted.");
    },
  },
  mounted() {
    this.getAddresses();
    this.getCart();
    // var shopcop = localStorage.getItem("wrong-copoun");
    // if (shopcop) {
    //   this.$router.push({ path: "/" });
    // }
    // this.selAddress();
    if (this.lang == "en") {
      this.mainText = textEn;
    } else {
      this.mainText = textAr;
    }
  },
  components: {
    loader,
    // NotFound,
  },
};
</script>

<style scoped></style>
