<template>
  <!--search-->
  <div class="search_area">
    <div class="search-icon mobile-search" @click="showSearch()">
      <div class="search-icons"></div>
    </div>

    <div class="searchbox pc-search">
      <form @submit.prevent="searchMethod()">
        <div class="input-group">
          <div class="search-icon" @click="searchMethod()">
            <div class="search-icons"></div>
          </div>
          <input
            v-if="lang == 'en'"
            type="text"
            v-model="search.text"
            class="form-control"
            placeholder="Search"
          />
          <input
            v-else
            type="text"
            v-model="search.text"
            class="form-control"
            placeholder="بحث"
          />
          <div class="input-group-prepend d-none">
            <button class="input-group-text" id="basic-addon1">
              <div class="search-icons"></div>
            </button>
          </div>
        </div>
      </form>
    </div>

    <transition mode="out-in" name="fade" class="mobile-search">
      <div class="searchbox" :class="{ show: show }" v-if="show == true">
        <span class="close" @click="hideSearch()"
          ><i class="fas fa-times"></i
        ></span>
        <form @submit.prevent="searchMethod()">
          <div class="input-group">
            <div class="search-icon" @click="searchMethod()">
              <div class="search-icons"></div>
            </div>
            <input
              v-if="lang == 'en'"
              type="text"
              v-model="search.text"
              class="form-control"
              placeholder="Search"
            />
            <input
              v-else
              type="text"
              v-model="search.text"
              class="form-control"
              placeholder="بحث"
            />
            <div class="input-group-prepend d-none">
              <button class="input-group-text" id="basic-addon1">
                <div class="search-icons"></div>
              </button>
            </div>
          </div>
        </form>
      </div>
    </transition>
    <transition mode="out-in" name="fade">
      <div
        class="overlay"
        @click="hideSearch()"
        :class="{ disblock: show }"
      ></div>
    </transition>
  </div>
  <!--search-->
</template>

<script>
export default {
  data() {
    return {
      show: false,
      search: {
        text: "",
      },
      lang: localStorage.getItem("shop_lang"),
    };
  },
  methods: {
    // search method
    searchMethod() {
      if (this.search.text !== "") {
        this.$router.push({
          name: "search",
          params: { text: this.search.text },
        });
      }
      this.search.text = "";
    },
    showSearch() {
      this.show = true;
    },
    hideSearch() {
      this.show = false;
    },
  },
};
</script>
