<template>
  <!--start Best Selling-->
  <section class="best-selling products site-info">
    <div class="container">
      <div class="main-title" v-if="mainText">
        <h3>{{ mainText.home.titles.bestSelling }}</h3>
      </div>
      <Loader v-if="$store.state.cardsLoading" class="fadeIn"></Loader>
      <div class="row fadeIn" v-else>
        <NotFound
          v-if="$store.state.homeData.best_selling == ''"
        ></NotFound>
        <!-- end of tabs links-->
        <div class="col-sm-12" v-else>
          <!-- tabs cotent -->
          <div class="best-carousel">
            <carousel
              :items="4"
              :loop="false"
              :autoplay="true"
              :dots="false"
              :margin="20"
              :responsive="{
                0: {
                  items: 2,
                  nav: true,
                },
                600: {
                  items: 2,
                  nav: true,
                },
                800: {
                  items: 4,
                  nav: true,
                },
              }"
              class="bes-car"
            >
              <div
                class="item"
                v-for="item in this.$store.state.homeData.best_selling"
                :key="item.id"
              >
                <div class="one-product">
                  <!-- product image-->
                  <router-link :to="'/product/' + item.id">
                    <div class="product-img">
                      <img v-lazy="item.main_image" />
                      <img class="hover_image" v-lazy="item.hover_image" />
                    </div>
                  </router-link>
                  <!-- product description -->
                  <div class="product-exp">
                    <!-- product title -->
                    <div class="product-title">
                      <h5>{{ item.name }}</h5>
                      <span> {{ item.brand_name }} </span>
                    </div>
                    <!-- product add , show and like -->
                    <div class="price-fav">
                      <div class="price">
                        <span class="new-price" v-if="item.is_offered != 0"
                          ><span class="currency"></span>
                          {{ item.offer_price }}</span
                        >
                        <span class="new-price" v-if="item.is_offered == 0"
                          ><span class="currency"></span> {{ item.price }}</span
                        >
                        <span class="old-price" v-if="item.is_offered != 0"
                          ><span class="currency"></span> {{ item.price }}</span
                        >
                      </div>
                      <ul class="list-unstyled" v-if="token">
                        <li class="like">
                          <button
                            @click="
                              $set(item, 'isActive', !item.isActive),
                                doLike(item.id)
                            "
                            v-if="item.is_favourite == false"
                            :class="[
                              'btn',
                              { likee: item.isActive },
                              { dislikee: !item.isActive },
                            ]"
                          ></button>
                          <!-- like -->
                          <button
                            @click="
                              $set(item, 'isActive', !item.isActive),
                                doLike(item.id)
                            "
                            v-else
                            :class="[
                              'btn',
                              { likee: !item.isActive },
                              { dislikee: item.isActive },
                            ]"
                          ></button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <span class="pro-stat" v-if="item.is_offered != 0">
                    <span v-if="lang == 'en'">Sale !</span>
                    <span v-else>تخفيض !</span>
                  </span>
                </div>
                <!-- <div class="text-center">
                  <router-link
                    :to="'/product/' + item.id"
                    class="dark-button button btn main-btn home-btn"
                  >
                    <span v-if="lang == 'en'">Select Option</span>
                    <span v-if="lang == 'ar'">حدد خيار</span>
                  </router-link>
                </div> -->
              </div>
            </carousel>
          </div>
        </div>
        <!-- show more products -->
        <div
          class="col-sm-12"
          v-if="$store.state.homeData.best_selling != ''"
        >
          <div class="show-more">
            <router-link to="/best-selling">
              {{ mainText.home.seeAll }}
              <span><img src="@/assets/images/long-arrow.png" /></span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--end Best-selling-->
</template>

<script>
import Loader from "./../Loading/cards";
import textAr from "../lang/ar.json";
import textEn from "../lang/en.json";
import NotFound from "./../Loading/not-found";
import carousel from "vue-owl-carousel";
export default {
  data() {
    return {
      loading: true,
      allData: "",
      token: localStorage.getItem("shop-token"),
      lang: localStorage.getItem("shop_lang"),
      mainText: "",
    };
  },
  mounted() {
    this.getData();
    if (this.lang == "en") {
      this.mainText = textEn;
    } else {
      this.mainText = textAr;
    }

    // this.$store.dispatch("addFavourite");
  },
  methods: {
    getData() {
      this.loading = false;
      // if (this.$store.state.homeData.best_selling != "") {
      // }
    },
    tabClick(val) {
      this.tabText = val;
    },
    // add to favourite
    doLike(id) {
      this.$store.dispatch("addFavourite", { id });
    },
  },
  components: {
    Loader,
    NotFound,
    carousel,
  },
};
</script>
