<template>
  <div>
    <!--start signin-->

    <div class="signin special-padding forget-password">
      <div class="container">
        <div class="row justify-content-center align-self-center">
          <div class="col-md-6">
            <div class="sign-form">
              <div class="logo">
                <router-link to="/" tag="a">
                  <img class="img-responsive" src="@/assets/images/logo.png" />
                </router-link>
                <p v-if="lang == 'en'">Forget Your Password?</p>
                <p v-if="lang == 'ar'">هل نسيت كلمة المرور ؟</p>
                <span v-if="lang == 'en'">Please Enter Your New Password </span>
                <span v-if="lang == 'ar'"
                  >من فضلك ادخل كلمة المرور الجديدة ؟
                </span>
              </div>
              <form>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="New Password"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Confirm New Password"
                      />
                    </div>
                  </div>
                </div>
                <div class="bottom-sign">
                  <button
                    formaction="signin.html"
                    type="submit"
                    class="btn main-btn"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="shape">
        <img src="@/assets/images/shapes/shape2.png" />
      </div>
    </div>

    <!--end signin-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      lang: localStorage.getItem("shop_lang"),
    };
  },
};
</script>
