<template>
  <section class="about-us-home">
    <div class="container">
      <div class="row">
        <div class="col-md-7 d-flex align-self-center">
          <div class="text_about">
            <h3>About Us</h3>
            <p v-html="textAbout.substring(0,300)+'....'"></p>
            <!-- show more products -->
            <div class="show-more" v-if="mainText">
              <router-link to="/about-us">
                {{ mainText.home.readMore }}
                <span><img src="@/assets/images/long-arrow.png" /></span>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-md-5 d-flex align-self-center justify-content-center">
          <div class="image_about">
            <img v-lazy="about_image" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import textAr from "../lang/ar.json";
import textEn from "../lang/en.json";
export default {
  name: "About_us_home",
  data() {
    return {
      mainText: "",
      lang: localStorage.getItem("shop_lang"),
      textAbout: "",
      about_image: "",
    };
  },
  mounted() {
    if (this.lang == "en") {
      this.mainText = textEn;
    } else {
      this.mainText = textAr;
    }
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      axios
        .get("general/settings", {
          headers: {
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          this.loading = false;
          this.textAbout = res.data.data.about;
          this.about_image = res.data.data.about_image;
        })
        .finally(() => (this.loading = false));
    },
  },
  components: {},
};
</script>
