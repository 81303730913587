<template>
  <div>
    <!--start single-product-->

    <ProductSlider></ProductSlider>

    <!--end single-product-->

    <!--start product description-->

    <ProductDetails></ProductDetails>

    <!--end product description-->

    <!--start Related-->

    <ProductRelated></ProductRelated>

    <!--end Related-->
  </div>
</template>

<script>
import ProductSlider from "./ProductSlider";
import ProductDetails from "./ProductDetails";
import ProductRelated from "./ProductRelated";
export default {
  data() {
    return {};
  },
  components: {
    ProductSlider,
    ProductDetails,
    ProductRelated,
  },
};
</script>
