<template>
  <!--start top-header-->
  <section
    class="top-header"
    :class="{ show_shadow: $route.name !== 'main-home' }"
  >
    <div class="container">
      <div class="row">
        <!-- START:: LOGO -->
        <div class="col-6 col-lg-3">
          <LogoSite />
        </div>

        <!-- START:: MAIN MENU -->

        <div class="col-6 col-lg-6">
          <!-- START:: SEARCH COMPONNET -->
          <Search></Search>
        </div>

        <!-- START::   -->

        <div class="col-6 col-lg-3">
          <div class="right-head">
            <!---sign up--->
            <div class="sign-btn" v-if="!token">
              <router-link to="/sign-up" v-if="lang === 'en'"
                >Sign Up</router-link
              >
              <router-link to="/sign-up" v-else>تسجيل دخول</router-link>
            </div>
            <!-- START:: CART COMPONNET -->
            <Cart v-if="token"></Cart>
            <!-- START:: FAV COMPONNET -->
            <Favourite v-if="token"></Favourite>
            <!-- link of profile on header -->
            <div v-if="token" class="profile-icon">
              <div class="text-icons">
                <router-link to="/my-account">
                  <div class="profile-icons"></div>
                </router-link>
                <span v-if="lang == 'en'">Profile</span>
                <span v-else>الحساب</span>
              </div>
            </div>
            <!-- START:: LANG APP -->
            <div class="profile-icon lang-icon">
              <div class="text-icons">
                <button
                  class="head_lang_app btn"
                  type="button"
                  @click="changeToAr"
                  v-if="lang == 'en'"
                >
                  <template >
                    <img src="../../../../assets/images/icons/us.png" />
                    <span>English</span>
                  </template>
                </button>

                <button
                  class="head_lang_app btn"
                  type="button"
                  @click="changeToEn"
                  v-else
                >
                  <template>
                    <img src="../../../../assets/images/icons/sa.png" />
                    <span>العربية</span>
                  </template>
                </button>
              </div>
            </div>

            <!-- END:: LANG APP -->
          </div>
        </div>
        <div class="col-md-12">
          <MainMenu />
        </div>
      </div>
    </div>
  </section>
  <!--end top-header-->
</template>

<script>
import LogoSite from "./logoSite.vue";
import MainMenu from "./MainMenu";
import Cart from "./Cart";
import Search from "./Search";
import Favourite from "./Favourite";
import axios from "axios";
export default {
  data() {
    return {
      show: false,
      search: {
        text: "",
      },
      // lang
      token: localStorage.getItem("shop-token"),
      lang: localStorage.getItem("shop_lang"),
    };
  },
  components: {
    MainMenu,
    Cart,
    Favourite,
    LogoSite,
    Search,
  },
  methods: {
    changeToAr() {
      localStorage.setItem("shop_lang", "ar");
      location.reload();
    },
    changeToEn() {
      localStorage.setItem("shop_lang", "en");
      location.reload();
    },
    // search method
    searchMethod() {
      if (this.search.text !== "") {
        this.$router.push({
          name: "search",
          params: { text: this.search.text },
        });
      }
    },
    hideSearch() {
      this.show = false;
    },
    showSearch() {
      this.show = true;
    },
    Logout() {
      axios
        .post("/auth/logout", [], {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then(() => {
          localStorage.removeItem("shop-token");
          setTimeout(() => {
            this.$router.push({ path: "/login" });
          }, 700);
        });
    },
  },
  mounted() {},
};
</script>
