<template>
  <div style="position: relative">
    <Loader v-if="loading"></Loader>
    <div class="profile-info fadeIn" v-else>
      <p class="user-info">
        {{ mainText.account.info.hello }} <span>{{ myData.user_name }}</span
        ><button class="btn" type="button" @click="Logout">
          {{ mainText.account.info.logout }}
        </button>
      </p>
      <p class="ori-one">
        {{ mainText.account.info.fromAccount }}
        <router-link to="/orders">
          {{ mainText.account.info.recentOrders }}</router-link
        >
      </p>
      <p class="ori-one">
        {{ mainText.account.info.manage }}
        <router-link to="/addresses">
          {{ mainText.account.info.shipBill }}</router-link
        >
      </p>
      <p class="ori-one">
        {{ mainText.account.info.edit }}
        <router-link to="/edit-password">
          {{ mainText.account.info.password }}
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "../../Loading/Loading";
import textAr from "../../lang/ar.json";
import textEn from "../../lang/en.json";
export default {
  data() {
    return {
      myData: "",
      loading: false,
      lang: localStorage.getItem("shop_lang"),
      mainText: "",
    };
  },
  methods: {
    getData() {
      this.loading = true;
      axios
        .get("/get-profile", {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          this.loading = false;
          this.myData = res.data.data;
        });
    },
    Logout() {
      axios
        .post("/auth/logout", [], {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then(() => {
          localStorage.removeItem("shop-token");
          setTimeout(() => {
            this.$router.push({ path: "/login" });
          }, 700);
        });
    },
  },
  mounted() {
    this.getData();
    if (this.lang == "en") {
      this.mainText = textEn;
    } else {
      this.mainText = textAr;
    }
  },
  components: {
    Loader,
  },
};
</script>
