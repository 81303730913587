<template>
  <!--start banner-->

  <section class="banners">
    <div class="container">
      <Loader v-if="loading" class="fadeIn"></Loader>
      <div class="row fadeIn custom-row" v-else>
        <div
          class="col-md-4"
          v-for="item in allData.slice(0, 3)"
          :key="item.id"
        >
          <div class="banner">
            <router-link :to="'/category/' + item.id"
              ><img :src="item.image"
            /></router-link>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--end banner-->
</template>
<script>
import axios from "axios";
import Loader from "./../Loading/banner";
export default {
  data() {
    return {
      loading: false,
      allData: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      axios
        .get("/general/all-categories", {
          headers: {
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          this.loading = false;
          this.allData = res.data.data;
        })
        .finally(() => (this.loading = false));
    },
  },
  components: {
    Loader,
  },
};
</script>
