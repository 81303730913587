var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"related products"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"rel-title"},[(_vm.lang == 'en')?_c('h3',[_vm._v("Related Products")]):_vm._e(),(_vm.lang == 'ar')?_c('h3',[_vm._v("منتجات ذات صلة")]):_vm._e()])])]),_c('div',{staticClass:"row"},[(_vm.loading)?_c('loader'):_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"related-carousel"},[_c('carousel',{attrs:{"items":4,"loop":false,"autoplay":true,"nav":true,"dots":false,"margin":20,"responsive":{
                0: {
                  items: 1,
                  nav: false,
                },
                600: {
                  items: 2,
                  nav: false,
                },
                800: {
                  items: 4,
                  nav: false,
                },
              }}},_vm._l((_vm.$store.state.profileData.you_may_like),function(item){return _c('div',{key:item.id,staticClass:"item"},[_c('div',{staticClass:"one-product"},[_c('router-link',{attrs:{"to":'/product/' + item.id}},[_c('div',{staticClass:"product-img"},[_c('img',{attrs:{"src":item.main_image}})])]),_c('div',{staticClass:"product-exp"},[_c('div',{staticClass:"product-title"},[_c('h5',[_vm._v(_vm._s(item.name))]),_c('span',[_vm._v(" "+_vm._s(item.brand_name)+" ")])]),_c('div',{staticClass:"price-fav"},[_c('div',{staticClass:"price"},[(item.is_offered != 0)?_c('span',{staticClass:"new-price"},[_c('span',{staticClass:"currency"}),_vm._v(" "+_vm._s(item.offer_price))]):_vm._e(),(item.is_offered == 0)?_c('span',{staticClass:"new-price"},[_c('span',{staticClass:"currency"}),_vm._v(" "+_vm._s(item.price))]):_vm._e(),(item.is_offered != 0)?_c('span',{staticClass:"old-price"},[_c('span',{staticClass:"currency"}),_vm._v(" "+_vm._s(item.price))]):_vm._e()])])]),(item.is_offered != 0)?_c('span',{staticClass:"pro-stat"},[_vm._v(_vm._s(item.discount)+" %")]):_vm._e()],1)])}),0)],1)])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }