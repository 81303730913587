<template>
  <div>
    <loader v-if="loading"></loader>
    <div class="orders" v-else>
      <h4 v-if="mainText">{{ mainText.account.orders.title }}</h4>
      <NotFound v-if="myOrders == ''"></NotFound>
      <table class="table" v-else>
        <thead>
          <tr>
            <th scope="col">{{ mainText.account.orders.table.order }}</th>
            <th scope="col">{{ mainText.account.orders.table.date }}</th>
            <th scope="col">{{ mainText.account.orders.table.status }}</th>
            <th scope="col">{{ mainText.account.orders.table.orderImage }}</th>
            <th scope="col">{{ mainText.account.orders.table.total }}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="order in myOrders" :key="order.id">
            <th scope="row">#{{ order.id }}</th>
            <td>{{ order.created_at }}</td>
            <td v-if="order.status === 'pending'" class="status pending">
              {{ mainText.account.orders.status.pending }}
            </td>
            <td v-if="order.status === 'processing'" class="status processing">
              {{ mainText.account.orders.status.processing }}
            </td>
            <td v-if="order.status === 'finished'" class="status deliverd">
              {{ mainText.account.orders.status.finished }}
            </td>
            <td v-if="order.status === 'accepted'" class="status accepted">
              Accepted
            </td>
            <td v-if="order.status === 'cancelled'" class="status cancelled">
              {{ mainText.account.orders.status.cancelled }}
            </td>
            <td v-if="order.status === 'in_way'" class="status in_way">
              {{ mainText.account.orders.status.inWay }}
            </td>
            <td>
              <div class="order-img">
                <div v-for="img in order.images" :key="img">
                  <img :src="img" v-if="img" />
                </div>
              </div>
            </td>
            <td><span class="currency"></span> {{ order.final_price }}</td>
            <td>
              <router-link
                :to="'/order-details/' + order.id"
                class="btn main-btn"
                >{{ mainText.account.orders.view }}</router-link
              >
            </td>
            <td v-if="order.status == 'pending'">
              <button
                type="button"
                class="btn main-btn"
                @click="AcceptStatus(order.id)"
              >
                {{ mainText.account.orders.cancel }}
              </button>
            </td>
            <td v-if="order.status == 'in_way'">
              <button
                type="button"
                class="btn main-btn"
                @click="finishStatus(order.id)"
              >
                انهاء
              </button>
            </td>
          </tr>
         
         
        </tbody>
      </table>
    </div>
    <!-- alert -->
    <transition name="fade">
      <div class="all-alert" v-if="alert.alertShow">
        <div class="alert-section">
          <span>
            <i class="fas fa-exclamation-triangle"></i>
          </span>
          <h3>{{ mainText.account.orders.cancelAlert }}</h3>
          <ul>
            <li>
              <button type="button" class="back" @click="confirmAlert">
                {{ mainText.yes }}
              </button>
            </li>
            <li>
              <button
                type="button"
                class="delete"
                @click="alert.alertShow = false"
              >
                {{ mainText.no }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </transition>
    <!-- alert -->
    <transition name="fade">
      <div class="all-alert" v-if="alert.alertDone">
        <div class="alert-section">
          <span>
            <i class="fas fa-exclamation-triangle"></i>
          </span>
          <h3 v-if="lang == 'ar'">هل أنت متأكد من أنه تم استلام الطلب ؟</h3>
          <h3 v-if="lang == 'en'">Do you deliver this order ?</h3>
          <ul>
            <li>
              <button
                type="button"
                v-if="lang == 'ar'"
                class="back"
                @click="doneStatus"
              >
                تأكيد
              </button>
              <button
                type="button"
                v-if="lang == 'en'"
                class="back"
                @click="doneStatus"
              >
                yes
              </button>
            </li>
            <li>
              <button
                type="button"
                class="delete"
                v-if="lang == 'ar'"
                @click="alert.alertDone = false"
              >
                تراجع
              </button>
              <button
                type="button"
                class="delete"
                v-if="lang == 'en'"
                @click="alert.alertDone = false"
              >
                no
              </button>
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import textAr from "../../lang/ar.json";
import textEn from "../../lang/en.json";
import loader from "../../Loading/Loading";
import NotFound from "../../Loading/not-found";
export default {
  data() {
    return {
      myOrders: "",
      loading: false,
      lang: localStorage.getItem("shop_lang"),
      mainText: "",
      order_id: "",
      alert: {
        alertShow: false,
        alertDone: false,
      },
    };
  },
  methods: {
    getOrders() {
      this.loading = true;
      axios
        .get("/my/orders", {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            Accept: "application/json",
            lang: "en",
          },
        })
        .then((res) => {
          this.loading = false;
          this.myOrders = res.data.data;
        });
    },
    AcceptStatus(id) {
      this.alert.alertShow = true;
      this.order_id = id;
    },
    confirmAlert() {
      this.alert.alertShow = false;
      this.loading = true;
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });
      const data = new FormData();
      data.append("id", this.order_id);
      axios
        .post("/cancel/order", data, {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then(() => {
          // this.loading = false;
          this.getOrders();
        });
    },
    finishStatus(id) {
      this.alert.alertDone = true;
      this.order_id = id;
    },
    doneStatus() {
      this.alert.alertDone = false;
      this.loading = true;
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });
      const data = new FormData();
      data.append("id", this.order_id);
      axios
        .post("/finish/order", data, {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then(() => {
          this.getOrders();
        });
    },
  },
  components: {
    loader,
    NotFound,
  },
  mounted() {
    this.getOrders();
    if (this.lang == "en") {
      this.mainText = textEn;
    } else {
      this.mainText = textAr;
    }
  },
};
</script>
