<template>
  <div>
    <section class="related products">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="rel-title">
              <h3 v-if="lang == 'en'">Related Products</h3>
              <h3 v-if="lang == 'ar'">منتجات ذات صلة</h3>
            </div>
          </div>
        </div>
        <div class="row">
          <loader v-if="loading"></loader>
          <div class="col-sm-12" v-else>
            <div class="related-carousel">
              <carousel
                :items="4"
                :loop="false"
                :autoplay="true"
                :nav="true"
                :dots="false"
                :margin="20"
                :responsive="{
                  0: {
                    items: 1,
                    nav: false,
                  },
                  600: {
                    items: 2,
                    nav: false,
                  },
                  800: {
                    items: 4,
                    nav: false,
                  },
                }"
              >
                <div
                  class="item"
                  v-for="item in $store.state.profileData.you_may_like"
                  :key="item.id"
                >
                  <div class="one-product">
                    <!-- product image-->
                    <router-link :to="'/product/' + item.id">
                      <div class="product-img">
                        <img :src="item.main_image" />
                      </div>
                    </router-link>
                    <!-- product description -->
                    <div class="product-exp">
                      <!-- product title -->
                      <div class="product-title">
                        <h5>{{ item.name }}</h5>
                        <span> {{ item.brand_name }} </span>
                      </div>
                      <!-- product add , show and like -->
                      <div class="price-fav">
                        <div class="price">
                          <span class="new-price" v-if="item.is_offered != 0"
                            ><span class="currency"></span>
                            {{ item.offer_price }}</span
                          >
                          <span class="new-price" v-if="item.is_offered == 0"
                            ><span class="currency"></span>
                            {{ item.price }}</span
                          >
                          <span class="old-price" v-if="item.is_offered != 0"
                            ><span class="currency"></span>
                            {{ item.price }}</span
                          >
                        </div>
                        
                      </div>
                    </div>
                    <span class="pro-stat" v-if="item.is_offered != 0"
                      >{{ item.discount }} %</span
                    >
                  </div>
                </div>
              </carousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
import loader from "../../Loading/cards";
export default {
  data() {
    return {
      allData: "",
      id: this.$route.params.id,
      loading: false,
      token: localStorage.getItem("shop-token"),
      lang: localStorage.getItem("shop_lang"),
    };
  },
  methods: {
    // add to favourite
    
  },
  components: { carousel, loader },
  mounted() {
  },
};
</script>
