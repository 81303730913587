<template>
  <div v-if="mainText">
    <section class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-sm-12">
            <div class="foot">
              <h4>{{ mainText.footer.menu.title }}</h4>
              <div class="foot-links">
                <ul class="list-unstyled">
                  <!-- <li>
                    <router-link to="">{{
                      mainText.footer.menu.help
                    }}</router-link>
                  </li> -->
                  <li>
                    <router-link to="/faqs">{{
                      mainText.footer.menu.faq
                    }}</router-link>
                  </li>
                  <li>
                    <router-link to="/shipping">{{
                      mainText.footer.menu.shipping
                    }}</router-link>
                  </li>
                  <li v-if="token">
                    <router-link to="/order-tracking">{{
                      mainText.footer.menu.orderTrack
                    }}</router-link>
                  </li>
                  <!-- <li>
                    <router-link to="/return-product">{{
                      mainText.footer.menu.returnProduct
                    }}</router-link>
                  </li> -->
                </ul>
                <ul class="list-unstyled">
                  <li>
                    <router-link to="/vat">{{
                      mainText.footer.menu.vat
                    }}</router-link>
                  </li>
                  <li>
                    <router-link to="/privacy-policy">{{
                      mainText.footer.menu.privacy
                    }}</router-link>
                  </li>
                  <!-- <li>
                    <router-link to="/terms-of-use">{{
                      mainText.footer.menu.termsUse
                    }}</router-link>
                  </li> -->
                  <li>
                    <router-link to="/terms-of-sell">{{
                      mainText.footer.menu.termsSell
                    }}</router-link>
                  </li>
                  <li>
                    <router-link to="/terms-conditions">{{
                      mainText.footer.menu.condition
                    }}</router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-12">
            <div class="foot-logo">
              <div class="image">
                <img src="@/assets/images/logo-white.png" />
              </div>
              
              <div class="email-phone">
                <a :href="'mailto:' + social.email">{{ social.email }}</a>
                <a :href="'tel:' + social.phone"> {{ social.phone }} </a>
              </div>
            </div>
            <div class="my-foot-select">
               

              <!-- START:: LANG APP -->
              <div class="lang_app">
                <button
                  class="head_lang_app btn"
                  type="button"
                  @click="toggleLang"
                >
                  <template v-if="lang == 'en' ">
                    <h5>English</h5>
                    <img src="../../../assets/images/icons/us.png" />
                  </template>
                  <template v-if="lang == 'ar' ">
                    <h5>العربية</h5>
                    <img src="../../../assets/images/icons/eg.png" />
                  </template>

                  <img
                    class="notRotateArrow"
                    :class="{ rotateArrow: showLangList }"
                    src="../../../assets/images/angle-down-w.png"
                  />
                </button>

                <transition name="fade" v-if="showLangList">
                  <div class="body_lang_app">
                    <button
                      class="single_lang btn"
                      type="button"
                      v-for="singlLang in languages"
                      :key="singlLang.id"
                      @click="changeLang(singlLang.code), toggleLang()"
                    >
                      <h5>{{ singlLang.name }}</h5>
                      <img :src="singlLang.url" />
                    </button>
                  </div>
                </transition>
              </div>
              <!-- END:: LANG APP -->
            </div>
          </div>
          <div class="col-lg-3 col-sm-12">
            <div class="foot">
              <h4>{{ mainText.footer.download }}</h4>
              <div class="media">
                <img
                  class="mr-3"
                  src="@/assets/images/iPhone 11 Pro Outline.png"
                  alt="Generic placeholder image"
                />
                <div class="media-body">
                  <div>
                    <h5 class="mt-0">{{ mainText.footer.offer }}</h5>
                    <p>{{ mainText.footer.only }}</p>
                  </div>
                  <div class="downapp">
                    <a
                      href="https://apps.apple.com/us/app/rs-woman/id1597265309"
                      target="_blank"
                      ><img src="@/assets/images/app-store.png"
                    /></a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.alalmiyalhura.abayas"
                      target="_blank"
                      ><img src="@/assets/images/google-play.png"
                    /></a>
                  </div>
                </div>
              </div>
              <div class="pay">
                <img src="@/assets/images/payments.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="copyright">
      <p>{{ mainText.footer.copyright }}</p>
    </div>
  </div>
</template>

<script>
import egFlag from "../../../assets/images/icons/eg.png";
import usFlag from "../../../assets/images/icons/us.png";
import axios from "axios";
import textAr from "../lang/ar.json";
import textEn from "../lang/en.json";
export default {
  data() {
    return {
      lang: localStorage.getItem("shop_lang"),
      mainText: null,
      about: "",
      social: "",
      token: localStorage.getItem("shop-token"),
      showLangList: false,
      languages: [
        {
          id: 1,
          name: "العربية",
          url: egFlag,
          code: "ar",
        },
        {
          id: 2,
          name: "English",
          url: usFlag,
          code: "en",
        },
      ],
    };
  },
  methods: {
    toggleLang() {
      this.showLangList = !this.showLangList;
    },
    changeLang(code) {
      if (code == "en") {
        localStorage.setItem("shop_lang", "en");
      } else {
        localStorage.setItem("shop_lang", "ar");
      }
      location.reload();
    },
    getAbout() {
      axios
        .get("/general/settings", {
          headers: {
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          this.about = res.data.data;
        });
    },
    getSocial() {
      axios
        .get("/general/settings", {
          headers: {
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          this.social = res.data.data.contact_us;
        });
    },
  },
  mounted() {
    this.getAbout();
    this.getSocial();
    if (this.lang == "en") {
      this.mainText = textEn;
    } else {
      this.mainText = textAr;
    }
  },
};
</script>
