<template>
  <div>
    <section class="product-description">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="top-part">
              <h3>{{ mainText.product.details.productDetails }}</h3>
              <p v-html="allData.description"></p>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="bottom-part">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" v-if="allData.specifications != ''">
                  <a
                    class="nav-link"
                    :class="{ active: allData.specifications != '' }"
                    id="specification-tab"
                    data-toggle="tab"
                    href="#specification"
                    role="tab"
                    aria-controls="specification"
                    aria-selected="false"
                    >{{ mainText.product.details.specification }}</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    :class="{ active: allData.specifications == '' }"
                    id="rating-tab"
                    data-toggle="tab"
                    href="#rating"
                    role="tab"
                    aria-controls="rating"
                    aria-selected="false"
                    >{{ mainText.product.details.rating }}</a
                  >
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <!-- <div
                  class="tab-pane fade show active"
                  id="overview"
                  role="tabpanel"
                  aria-labelledby="overview-tab"
                >
                  <div class="overview-part">
                    <h4>Highlights</h4>
                    <p>
                      {{ allData.description }}
                    </p>
                  </div>
                </div> -->
                <div
                  class="tab-pane fade"
                  :class="[allData.specifications != '' ? 'show active' : '']"
                  id="specification"
                  role="tabpanel"
                  aria-labelledby="specification-tab"
                >
                  <div class="row">
                    <div
                      class="col-md-6"
                      v-for="spec in allData.specifications"
                      :key="spec.id"
                    >
                      <table class="table table-striped">
                        <tbody>
                          <tr>
                            <td>{{ spec.title }}</td>
                            <td>{{ spec.body }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  :class="[allData.specifications == '' ? 'show active' : '']"
                  id="rating"
                  role="tabpanel"
                  aria-labelledby="rating-tab"
                >
                  <div class="overall-rate">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="overall-num">
                              <p v-if="lang === 'en'">OVERALL RATING</p>
                              <p v-else>كل التقييمات</p>
                              <span>{{ rate }}</span>
                              <div class="ov-rating">
                                <ul class="list-unstyled">
                                  <li
                                    class="full-star"
                                    v-for="star in rate"
                                    :key="star.id"
                                  >
                                    <span><i class="fas fa-star"></i></span>
                                  </li>
                                  <li
                                    class="gray-star"
                                    v-for="star in stars - rate"
                                    :key="star.id"
                                  >
                                    <span><i class="fas fa-star"></i></span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-8">
                            <!-- <div class="all-progress">

                              <div class="progress">
                                <div
                                  class="progress-bar"
                                  role="progressbar"
                                  aria-valuenow="90"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                  :style="'width:' + 90 + '%'"
                                ></div>
                              </div>

                              <div class="progress">
                                <div
                                  class="progress-bar"
                                  role="progressbar"
                                  aria-valuenow="60"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                  style="width: 60%"
                                ></div>
                              </div>

                              <div class="progress">
                                <div
                                  class="progress-bar"
                                  role="progressbar"
                                  aria-valuenow="45"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                  style="width: 45%"
                                ></div>
                              </div>

                              <div class="progress">
                                <div
                                  class="progress-bar"
                                  role="progressbar"
                                  aria-valuenow="25"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                  style="width: 25%"
                                ></div>
                              </div>

                              <div class="progress">
                                <div
                                  class="progress-bar"
                                  role="progressbar"
                                  aria-valuenow="10"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                  style="width: 10%"
                                ></div>
                              </div>

                            </div> -->
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="add-rate">
                          <a href="#add-rate">{{
                            mainText.product.details.addRate
                          }}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="col-sm-12"
                      v-for="review in allData.reviews"
                      :key="review.id"
                    >
                      <div class="rating-box">
                        <div class="media">
                          <img
                            :src="review.avatar"
                            alt="Generic placeholder image"
                          />
                          <div class="media-body">
                            <h5 class="mt-0">{{ review.name }}</h5>
                            <div class="user-rating">
                              <ul class="list-unstyled">
                                <li
                                  class="full-star"
                                  v-for="star in review.rate"
                                  :key="star.id"
                                >
                                  <span><i class="fas fa-star"></i></span>
                                </li>
                                <li
                                  class="gray-star"
                                  v-for="star in stars - review.rate"
                                  :key="star.id"
                                >
                                  <span><i class="fas fa-star"></i></span>
                                </li>
                              </ul>
                            </div>
                            <p>
                              {{ review.comment }}
                            </p>
                          </div>
                        </div>
                        <!-- <span class="date">10 Oct, 2018</span> -->
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="rate-section" id="add-rate">
                        <form>
                          <span>{{ mainText.product.details.addRate }}</span>
                          <div class="rating">
                            <input
                              name="stars"
                              id="d5"
                              v-model="revData.rate"
                              value="5"
                              type="radio"
                            /><label for="d5"
                              ><i class="fas fa-star"></i
                            ></label>
                            <input
                              name="stars"
                              v-model="revData.rate"
                              value="4"
                              id="d4"
                              type="radio"
                            /><label for="d4"
                              ><i class="fas fa-star"></i
                            ></label>
                            <input
                              name="stars"
                              v-model="revData.rate"
                              value="3"
                              id="d3"
                              type="radio"
                            /><label for="d3"
                              ><i class="fas fa-star"></i
                            ></label>
                            <input
                              name="stars"
                              v-model="revData.rate"
                              value="2"
                              id="d2"
                              type="radio"
                            /><label for="d2"
                              ><i class="fas fa-star"></i
                            ></label>
                            <input
                              name="stars"
                              v-model="revData.rate"
                              value="1"
                              id="d1"
                              type="radio"
                            /><label for="d1"
                              ><i class="fas fa-star"></i
                            ></label>
                          </div>
                          <span>{{ mainText.product.details.addRev }}</span>
                          <input
                            type="text"
                            v-model="revData.comment"
                            class="form-control"
                            :placeholder="mainText.product.details.addRev"
                          />

                          <button
                            type="button"
                            @click="addRate"
                            ref="save"
                            class="dark-button rate-button button btn main-btn"
                          >
                            <i class="fas fa-spinner"></i>
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- error alert -->
        <transition name="slide-fade">
          <div class="error-alert" v-if="error.static.error">
            <span @click="error.static.error = false">
              <i class="fas fa-times"></i>
            </span>
            <p>{{ error.static.text }}</p>
          </div>
        </transition>
        <!-- success alert -->
        <transition name="slide-fade">
          <div class="success-alert" v-if="success">
            <span @click="success = false"> <i class="fas fa-times"></i> </span>
            <p>{{ mainText.addRate.done }}</p>
          </div>
        </transition>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import textAr from "../../lang/ar.json";
import textEn from "../../lang/en.json";
export default {
  data() {
    return {
      allData: "",
      id: this.$route.params.id,
      rate: "",
      stars: 5,
      progress_rate: [],
      revData: {
        product_id: "",
        rate: "",
        comment: "",
      },
      lang: localStorage.getItem("shop_lang"),
      mainText: "",
      waiting: false,
      error: {
        errorEmail: false,
        errorPassword: false,
        static: {
          error: false,
          text: "",
        },
      },
      success: false,
    };
  },
  methods: {
    getData() {
      this.loading = true;
      axios
        .get("/general/product-details/" + this.id, {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          this.loading = false;
          this.allData = res.data.data;
          this.rate = parseInt(res.data.data.rate);
        })
        .finally(() => (this.loading = false));
    },
    addRate() {
      this.waiting = true;
      if (this.revData.rate == "") {
        setTimeout(() => {
          this.error.static.error = true;
          this.error.static.text = this.mainText.addRate.rate;
          this.waiting = false;
          this.success = false;
        }, 1000);
      } else if (this.revData.comment == "") {
        setTimeout(() => {
          this.error.static.error = true;
          this.error.static.text = this.mainText.addRate.review;
          this.waiting = false;
          this.success = false;
        }, 1000);
      } else {
        const submitButton = this.$refs["save"];
        submitButton.classList.add("click");
        setTimeout(() => {
          submitButton.classList.remove("click");
        }, 1000);
        const data = new FormData();
        data.append("product_id", this.id);
        data.append("rate", this.revData.rate);
        data.append("comment", this.revData.comment);
        axios
          .post("/create/review", data, {
            headers: {
              Authorization: "bearer" + localStorage.getItem("shop-token"),
              "cache-control": "no-cache",
              "Content-type": "application/json",
              Accept: "application/json",
              lang: localStorage.getItem("shop_lang"),
            },
          })
          .then(() => {
            // location.reload();
            this.getData();
            this.success = true;
            (this.revData.comment = ""),
              (this.revData.rate = ""),
              setTimeout(() => {
                this.success = false;
              }, 1000);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  mounted() {
    this.getData();
    if (this.lang == "en") {
      this.mainText = textEn;
    } else {
      this.mainText = textAr;
    }
  },
};
</script>
