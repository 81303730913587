<template>
  <div>
    <div class="account-details">
      <div class="my-form">
        <form>
          <div class="edit-account">
            <h3>{{ mainText.passworChange.title }}</h3>
            <div class="form-group">
              <div class="row">
                <div class="col-md-3">
                  <label class="">{{
                    mainText.passworChange.form.currentPassword
                  }}</label>
                </div>
                <div class="col-md-9">
                  <input
                    type="password"
                    class="form-control"
                    :placeholder="mainText.passworChange.form.currentPassword"
                    v-model="data.currentPassword"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-3">
                  <label class="">{{
                    mainText.passworChange.form.newPassword
                  }}</label>
                </div>
                <div class="col-md-9">
                  <input
                    type="password"
                    class="form-control"
                    :placeholder="mainText.passworChange.form.newPassword"
                    v-model="data.newPassword"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-3">
                  <label class="">{{
                    mainText.passworChange.form.confirmPassword
                  }}</label>
                </div>
                <div class="col-md-9">
                  <input
                    type="password"
                    class="form-control"
                    :placeholder="mainText.passworChange.form.confirmPassword"
                    v-model="data.newPasswordConfirmation"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="dash-btn">
                <!-- <button
                  type="button"
                  ref="save"
                  @click="updateProfile"
                  class="main-btn btn"
                >
                  {{ mainText.save }}
                </button> -->
                <button
                  type="button"
                  ref="save"
                  @click="updateProfile"
                  class="dark-button save-button button btn main-btn"
                >
                  <i class="fas fa-spinner"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- error alert -->
    <transition name="slide-fade">
      <div class="error-alert" v-if="error.static.error">
        <span @click="error.static.error = false">
          <i class="fas fa-times"></i>
        </span>
        <p>{{ error.static.text }}</p>
      </div>
    </transition>
    <!-- success alert -->
    <transition name="slide-fade">
      <div class="success-alert" v-if="success">
        <span @click="success = false"> <i class="fas fa-times"></i> </span>
        <p>{{ mainText.passworChange.passUpdate }}</p>
      </div>
    </transition>
    <!--end signin-->
  </div>
</template>

<script>
import axios from "axios";
import textAr from "../../lang/ar.json";
import textEn from "../../lang/en.json";
export default {
  data() {
    return {
      data: {
        currentPassword: "",
        newPassword: "",
        newPasswordConfirmation: "",
      },
      error: {
        static: {
          error: false,
          text: "",
        },
      },
      success: false,
      lang: localStorage.getItem("shop_lang"),
      mainText: "",
    };
  },
  methods: {
    // update profile
    updateProfile() {
      const submitButton = this.$refs["save"];
      submitButton.classList.add("click");
      this.error.errorEmail = false;
      this.error.errorPassword = false;
      this.error.static.error = false;
      if (this.data.currentPassword == "") {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.error.static.error = true;
          this.error.static.text = this.mainText.passworChange.errors.currentPassword;
          this.success = false;
        }, 1000);
      } else if (this.data.newPassword == "") {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.error.static.error = true;
          this.error.static.text = this.mainText.passworChange.errors.newPassword;
          this.success = false;
        }, 1000);
      } else if (this.data.newPassword !== this.data.newPasswordConfirmation) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.error.static.error = true;
          this.error.static.text = this.mainText.passworChange.errors.confirmPassword;
          this.success = false;
        }, 1000);
      } else {
        const data = new FormData();
        data.append("current_password", this.data.currentPassword);
        data.append("new_password", this.data.newPassword);
        data.append(
          "new_password_confirmation",
          this.data.newPasswordConfirmation
        );
        axios
          .post("/change-password", data, {
            headers: {
              Authorization: "bearer" + localStorage.getItem("shop-token"),
              "cache-control": "no-cache",
              "Content-type": `multipart/form-data;`,
              Accept: "application/json",
              lang: "en",
            },
          })
          .then(() => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.error.static.error = false;
              this.success = true;
            }, 1000);
            setTimeout(() => {
              this.$router.push({ path: "/my-account" });
            }, 2000);
          })
          .catch((error) => {
            this.error.static.error = true;
            submitButton.classList.remove("click");
            this.waiting = false;
            this.error.errorEmail = false;
            this.error.errorPassword = false;
            this.error.static.text = error.response.data.msg;
          })
          .finally(() => (this.waiting = false));
      }
    },
  },
  mounted() {
    if (this.lang == "en") {
      this.mainText = textEn;
    } else {
      this.mainText = textAr;
    }
  },
};
</script>
