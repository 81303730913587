<template>
  <div>
    <!--end page-banner-->

    <div class="page-banner" :style="'background-image: url(' + subcategory_background + ')'">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <!--breadcrumbs-->
            <div class="breadcrumbs">
              <h3>{{ cat_sub_name }}</h3>
              <ul class="list-unstyled">
                <li v-if="lang == 'en'">
                  <router-link to="/" tag="a">Home </router-link> >
                </li>
                <li v-if="lang == 'ar'">
                  <router-link to="/" tag="a">الرئيسية </router-link> >
                </li>
                <li>{{ cat_main_name }} ></li>
                <li>{{ cat_sub_name }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end page-banner-->

    <!--start product-category-->

    <section class="product-category">
      <div class="container">
        <div class="all-filters">
          <div class="row fadeIn">
            <div class="col-md-4">
              <div class="right-filter">
                <div class="filterby">
                  <a @click="showFilterMenu()"
                    ><img src="@/assets/images/metro-equalizer.png"
                  /></a>
                  <span v-if="lang === 'en'">Filter By</span>
                  <span v-else>بحث بواسطة </span>
                </div>
                <!-- <div class="pagination">
                  <ul class="list-unstyled">
                    <li><a href="">2</a></li>
                    <li><a href="">3</a></li>
                    <li><a href="">4</a></li>
                  </ul>
                </div> -->
              </div>
            </div>
            <div class="col-md-4" v-if="allData.length != 0">
              <div class="filt-num text-center">
                <h4 v-if="lang === 'en'">{{ allData.length }} Products</h4>
                <h4 v-else>{{ allData.length }} منتج</h4>
              </div>
            </div>

            <div class="col-md-4" v-if="allData.length != 0">
              <div class="left-filter">
                <!-- <div class="looking-for">
                                <p>You're Looking For :</p>
                                <select class="my-select">
                                    <option>Dress</option>
                                    <option>Dress</option>
                                    <option>Dress</option>
                                </select>
                                <span>search</span>
                            </div> -->
                <!-- <div class="sortby">
                  <span>Sort By</span>
                  <select class="my-select">
                    <option>Latest</option>
                    <option>Latest</option>
                    <option>Latest</option>
                  </select>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <Loader v-if="loading" class="fadeIn"></Loader>
        <div class="products fadeIn" v-else>
          <NotFound v-if="allData == ''" class="fadeIn"></NotFound>
          <div class="row fadeIn" v-else>
            <!-- product card -->
            <div
              class="col-lg-3 col-md-4 col-sm-6 col-xl-3 col-6 fadeIn"
              v-for="item in loadMoreProducts"
              :key="item.id"
            >
              <div class="one-product">
                <!-- product image-->
                <router-link :to="'/product/' + item.id">
                  <div class="product-img">
                    <img v-lazy="item.main_image" />
                    <img class="hover_image" v-lazy="item.hover_image" />
                  </div>
                </router-link>
                <!-- product description -->
                <div class="product-exp">
                  <!-- product title -->
                  <div class="product-title">
                    <h5>{{ item.name }}</h5>
                    <span> {{ item.brand_name }} </span>
                  </div>
                  <!-- product add , show and like -->
                  <div class="price-fav">
                    <div class="price">
                      <span class="new-price" v-if="item.is_offered != 0"
                        ><span class="currency"></span>
                        {{ item.offer_price }}</span
                      >
                      <span class="new-price" v-if="item.is_offered == 0"
                        ><span class="currency"></span> {{ item.price }}</span
                      >
                      <span class="old-price" v-if="item.is_offered != 0"
                        ><span class="currency"></span> {{ item.price }}</span
                      >
                    </div>
                  </div>
                </div>
                <span class="pro-stat" v-if="item.is_offered != 0">
                  <span v-if="lang == 'en'">Sale !</span>
                  <span v-else>تخفيض !</span>
                </span>
              </div>
              <div class="text-center mb-5">
                <router-link
                  :to="'/product/' + item.id"
                  class="dark-button button btn main-btn home-btn"
                >
                  <span v-if="lang == 'en'">Select Option</span>
                  <span v-if="lang == 'ar'">حدد خيار</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center loadMore">
          <button
            class="dark-button button btn main-btn"
            @click="loadMore"
            v-if="currentPage * maxPerPage < filteredItems.length"
          >
            <span v-if="lang == 'ar'">عرض المزيد</span>
            <span v-if="lang == 'en'">Show More</span>
          </button>
        </div>
      </div>
      <div
        @click="hideFilterMenu()"
        class="overlay"
        :class="{ disblock: show }"
      ></div>
    </section>
    <!-- filter -->
    <div class="filter-menu" :class="{ show: show }">
      <a @click="hideFilterMenu()" class="close-filter only-mobile"
        ><i class="fas fa-times"></i
      ></a>
      <div class="filter-title">
        <h4 v-if="lang === 'en'">Filter</h4>
        <h4 v-else>بحث بواسطة</h4>
        <form>
          <div class="form-group">
            <h4 v-if="lang === 'en'">Popularity</h4>
            <h4 v-else>الأكثر شيوعاّ</h4>
            <select
              class="custom-select"
              v-if="lang === 'en'"
              v-model="filter.popularity"
              @change="getData(), getTitle()"
            >
              <option value="">choose from here</option>
              <option value="popularity">popularity</option>
              <option value="">no popularity</option>
            </select>
            <select
              class="custom-select"
              v-else
              v-model="filter.popularity"
              @change="getData(), getTitle()"
            >
              <option value="">إختر من هنا</option>
              <option value="popularity">أكثر شيوعاّ</option>
              <option value="">أقل شيوعاّ</option>
            </select>
          </div>
          <div class="form-group">
            <h4 v-if="lang === 'en'">Brands</h4>
            <h4 v-else>الماركات</h4>
            <select
              class="custom-select"
              v-model="filter.brand"
              @change="getData(), getTitle()"
            >
              <option value="" v-if="lang === 'en'">choose from here</option>
              <option value="" v-else>إختر من هنا</option>
              <option v-for="brand in brands" :key="brand.id" :value="brand.id">
                {{ brand.name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <h4 v-if="lang === 'en'">Price</h4>
            <h4 v-else>السعر</h4>
            <div class="price-slider">
              <input
                min="1"
                max="50000"
                step="10"
                type="range"
                v-model="min"
                @change="minPrice(min)"
              />
              <input
                min="1"
                max="50000"
                step="10"
                type="range"
                v-model="max"
                @change="maxPrice(max)"
              />
            </div>
            <div class="price-range">
              <span class="min"><span class="currency"></span> {{ min }}</span>
              <span class="max"><span class="currency"></span> {{ max }}</span>
            </div>
          </div>
          <!-- <div class="form-group" v-if="colors.length != 0">
            <div class="filter-color">
              <h4 v-if="lang === 'en'">Color</h4>
              <h4 v-else>اللون</h4>
              <span class="icon"><i class="fas fa-angle-down"></i></span>
            </div>
            <div class="my-colors">
              <div class="product-colors">
                <form>
                  <div class="color-section">
                    <div
                      class="color-picker__item"
                      v-for="color in colors"
                      :key="color.id"
                    >
                      <input
                        :id="'a' + color.id"
                        type="radio"
                        class="color-picker__input"
                        name="color-input"
                        :value="color.color"
                        @change="chooseColor(color.color)"
                      />
                      <label
                        :for="'a' + color.id"
                        class="color-picker__color"
                        :style="'background : ' + color.color"
                      ></label>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div> -->
          <div class="filter-btns">
            <button
              @click="resetFilter"
              type="button"
              class="thd-btn btn-block"
              v-if="lang === 'en'"
            >
              Reset Filter
            </button>
            <button
              @click="resetFilter"
              type="button"
              class="thd-btn btn-block"
              v-else
            >
              عرض كل المنتجات
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "../Loading/cards";
import NotFound from "./../Loading/not-found";
import textAr from "../lang/ar.json";
import textEn from "../lang/en.json";
export default {
  data() {
    return {
      loading: false,
      show: false,
      allData: "",
      cat_main_name: "",
      cat_sub_name: "",
      brands: "",
      colors: "",
      filter: {
        main_id: this.$route.params.main_id,
        sub_id: this.$route.params.sub_id,
        popularity: "",
        brand: "",
        color: "",
        rate: "",
      },
      filteredData: "",
      subcategory_background: "",

      token: localStorage.getItem("shop-token"),

      lang: localStorage.getItem("shop_lang"),
      mainText: "",
      min: 1,
      max: 50000,
      currentPage: 1,
      maxPerPage: 8,
      showReadMore: true,
    };
  },
  methods: {
    chooseColor(id) {
      this.filter.color = id;
      this.getData();
    },
    minPrice(price) {
      this.filter.min = price;
      this.getData();
      this.getTitle();
    },
    maxPrice(price) {
      this.filter.max = price;
      this.getData();
      this.getTitle();
    },
    resetFilter() {
      this.filter.popularity = "";
      this.filter.brand = "";
      this.filter.color = "";
      this.filter.rate = "";
      this.min = 1;
      this.max = 50000;
      this.getData();
      this.getTitle();
    },
    getTitle() {
      axios
        .get(
          "/general/productsByCatID?category_id=" +
            this.filter.main_id +
            "&sub_category_id=" +
            this.filter.sub_id,
          {
            headers: {
              Authorization: "bearer" + localStorage.getItem("shop-token"),
              "cache-control": "no-cache",
              "Content-type": "application/json",
              Accept: "application/json",
              lang: localStorage.getItem("shop_lang"),
            },
          }
        )
        .then((res) => {
          this.cat_main_name = res.data.cat_name;
          this.cat_sub_name = res.data.sub_cat_name;
        });
    },
    getSubCategoryData() {
      axios
        .get("/general/subcategory/" + this.filter.sub_id, {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          this.subcategory_background = res.data.data.subcategory_background;
          console.log(res.data.data);
        });
    },
    getData() {
      this.loading = true;
      const data = new FormData();
      if (this.filter.popularity) {
        data.append("popularity", this.filter.popularity);
      }
      if (this.filter.main_id) {
        data.append("category_id", this.filter.main_id);
      }
      if (this.filter.sub_id) {
        data.append("sub_category_id", this.filter.sub_id);
      }
      if (this.filter.brand) {
        data.append("brand_id", this.filter.brand);
      }
      // data.append("color", this.filter.color);
      if (this.min) {
        data.append("min_price", this.min);
      }
      if (this.max) {
        data.append("max_price", this.max);
      }
      axios
        .post("/general/filter-data", data, {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          this.loading = false;
          this.page = 1;
          this.allData = res.data.data;
          // this.perPage = 1;
        });
    },
    filterBrands() {
      axios
        .get("/general/all-brands", {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            lang: "en",
          },
        })
        .then((res) => {
          this.brands = res.data.data;
        });
    },
    filterColors() {
      axios
        .get("/general/all-colors", {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            lang: "en",
          },
        })
        .then((res) => {
          this.colors = res.data.data;
        });
    },
    // add to favourite
    doLike(id) {
      this.$store.dispatch("addFavourite", { id });
    },
    showFilterMenu() {
      this.show = true;
    },
    hideFilterMenu() {
      this.show = false;
    },
    // pagenation
    loadMore() {
      this.currentPage += 1;
    },
  },
  computed: {
    filteredItems() {
      return this.allData.slice();
    },
    lastPage() {
      let length = this.allData.length;
      return Math.floor(length / this.perPage) + 1;
    },

    totalResults() {
      return Object.keys(this.filteredItems).length;
    },
    pageCount() {
      return Math.ceil(this.totalResults / this.maxPerPage);
    },
    pageOffest() {
      return this.maxPerPage * this.currentPage;
    },
    loadMoreProducts() {
      return this.filteredItems.slice(0, this.currentPage * this.maxPerPage);
    },
  },
  mounted() {
    this.getData();
    this.getTitle();
    this.filterBrands();
    this.filterColors();
    this.getSubCategoryData();
    if (this.lang == "en") {
      this.mainText = textEn;
    } else {
      this.mainText = textAr;
    }
  },
  components: {
    Loader,
    NotFound,
  },
};
</script>

<style scoped>
.filter-color {
  display: flex;
  margin-bottom: 10px;
}
.filter-color span.icon {
  font-size: 20px !important;
  font-weight: 500 !important;
  display: none;
}
.my-colors .color-section {
  display: flex;
  padding: 0 1.75rem 0 0.75rem;
}
.my-colors .product-colors form {
  margin: 0 !important;
}

.my-colors .color-picker__color {
  border-radius: 50px;
}

input[type="range"] {
  box-shadow: 0;
  outline: 0;
}
.price-slider {
  width: 100%;
  margin: auto;
  text-align: center;
  position: relative;
  height: 15px;
  margin-bottom: 15px;
}
.price-slider svg,
.price-slider input[type="range"] {
  position: absolute;
  left: 0;
  bottom: 0;
}
input[type="number"] {
  border: 1px solid #ddd;
  text-align: center;
  font-size: 1.6em;
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"]:invalid,
input[type="number"]:out-of-range {
  border: 2px solid #e60023;
}
input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #034b9d;
}
input[type="range"]:focus::-ms-fill-lower {
  background: #034b9d;
}
input[type="range"]:focus::-ms-fill-upper {
  background: #034b9d;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: #034b9d;
  border-radius: 1px;
  box-shadow: none;
  border: 0;
}
input[type="range"]::-webkit-slider-thumb {
  z-index: 2;
  position: relative;
  box-shadow: 0px 0px 0px #000;
  border: 1px solid #4e85c3;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #4e85c3;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: #034b9d;
  border-radius: 1px;
  box-shadow: none;
  border: 0;
}
input[type="range"]::-moz-range-thumb {
  z-index: 2;
  position: relative;
  box-shadow: 0px 0px 0px #000;
  border: 1px solid #034b9d;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #3977be;
  cursor: pointer;
}
input[type="range"]::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type="range"]::-ms-fill-lower,
input[type="range"]::-ms-fill-upper {
  background: #034b9d;
  border-radius: 1px;
  box-shadow: none;
  border: 0;
}
input[type="range"]::-ms-thumb {
  z-index: 2;
  position: relative;
  box-shadow: 0px 0px 0px #000;
  border: 1px solid #034b9d;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #3977be;
  cursor: pointer;
}

.price-range {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.price-range span {
  font-size: 16px;
}
</style>
