<template>
  <!-- link of cart on header -->
  <div class="cart-icon">
    <div class="text-icons">
      <a @click="showCart()" class="btn-count">
        <div class="cart-icons">
          <small class="myCount">
            {{ $store.state.cartCountProduct.product_count }}
          </small>
        </div>
      </a>
      <span v-if="lang == 'en'">Cart</span>
      <span v-else>السلة</span>
    </div>
    <!-- side menu of favourite items -->
    <div class="cart-menu" :class="{ show: show }">
      <a @click="hideCart()" class="close-cart only-mobile"
        ><i class="fas fa-times"></i
      ></a>
      <div class="cart-title" v-if="mainText">
        <h4>{{ mainText.home.cart.title }}</h4>
        <span class="cart-number"
          >{{ $store.state.cartCountProduct.product_count }}
          {{ mainText.home.cart.subTitle }}</span
        >
      </div>
      <Loader v-if="$store.state.cartloading" class="fadeIn"></Loader>
      <div class="fadeIn" v-else>
        <NotFound v-if="$store.state.cartList == ''" class="fadeIn"></NotFound>
        <ul class="list-unstyled fadeIn" v-else>
          <!-- cart item -->
          <li v-for="cart in $store.state.cartList" :key="cart.cart_id">
            <div class="cart-item">
              <div class="cart-prod">
                <div class="cart-prod-img">
                  <img :src="cart.product_image" />
                </div>
                <div class="cart-prod-tit">
                  <h4>{{ cart.product_name }}</h4>
                  <span class="cat">{{ cart.category_name }}</span>
                  <h5>
                    {{ cart.product_price }} <span class="currency cart"></span>
                  </h5>
                </div>
              </div>
              <div class="left-part-cart">
                <div class="d-flex">
                  <div class="input-group counter">
                    <div class="input-group-minus">
                      <button
                        class="input-group-text btn-minus"
                        @click="
                          counterMinus(cart.cart_id, cart.product_quantity)
                        "
                      >
                        -
                      </button>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      v-model="cart.product_quantity"
                    />
                    <div class="input-group-plus">
                      <button
                        class="input-group-text btn-plus"
                        @click="counterAdd(cart.cart_id, cart.product_quantity)"
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div class="cart-remove-item">
                    <button
                      type="button"
                      class="btn"
                      @click="deleateItem(cart.cart_id)"
                    >
                      <i class="far fa-trash-alt"></i>
                    </button>
                  </div>
                </div>
                <div
                  class="color_size_cart d-flex mt-2 justify-content-between"
                >
                  <div class="size-section">
                    <div class="size-picker-item">
                      <label for="size.id" class="siz-picker">
                        {{ cart.size_name }}
                      </label>
                    </div>
                  </div>
                  <div class="color-section">
                    <div class="color-picker__item">
                      <label
                        for="color.id"
                        class="color-picker__color"
                        :style="'background: ' + cart.color_name"
                      ></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <!-- cart item -->
        </ul>
      </div>
      <div class="cart-copoun" v-if="$store.state.cartList != ''">
        <p v-if="error.static.error">
          {{ error.static.text }}
        </p>
        <div class="copoun-text">
          <input
            class="form-control"
            type="text"
            v-model="copounVal"
            @focus="error.static.error = false"
            :placeholder="mainText.home.cart.copoun"
          />
          <button
            type="button"
            @click="copounCheck()"
            class="apply-btn"
            v-if="lang == 'en'"
          >
            Apply
          </button>
          <button type="button" @click="copounCheck()" class="apply-btn" v-else>
            تأكيد
          </button>
        </div>
        <div class="cart-flex cart-subtotal">
          <h5>{{ mainText.home.cart.billDetails.subtotal }}</h5>
          <h4>
            {{ $store.state.cartCountProduct.sub_total_price }}
            <span class="currency"></span>
          </h4>
        </div>
        <div class="cart-flex cart-discount fadeIn">
          <h5>{{ mainText.home.cart.billDetails.discount }}</h5>
          <h4>
            {{ $store.state.cartCountProduct.discount }}
            <span class="currency"></span>
          </h4>
        </div>
        <div class="cart-flex cart-Shipping">
          <h5>{{ mainText.home.cart.billDetails.shipping }}</h5>
          <h4>
            {{ $store.state.cartCountProduct.shipping_price }}
            <span class="currency"></span>
          </h4>
        </div>
        <div class="cart-flex total">
          <h5>{{ mainText.home.cart.billDetails.total }}</h5>
          <h4>
            {{ $store.state.cartCountProduct.final_price }}
            <span class="currency"></span>
          </h4>
        </div>
      </div>
      <div
        class="checkout"
        v-if="$store.state.cartList != ''"
        @click="hideCart()"
      >
        <router-link to="/checkout" class="main-btn">{{
          mainText.home.cart.checkout
        }}</router-link>
      </div>
    </div>
    <div @click="hideCart()" class="overlay" :class="{ disblock: show }"></div>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "../../Loading/sideMenu";
import NotFound from "./../../Loading/not-found";
import textAr from "../../lang/ar.json";
import textEn from "../../lang/en.json";
export default {
  data() {
    return {
      show: false,
      // $store.state.cartCountProduct: "",
      otherData: "",
      loading: false,
      counter: 1,
      lang: localStorage.getItem("shop_lang"),
      mainText: "",
      copounVal: "",
      error: {
        static: {
          error: false,
          text: "",
        },
      },
      success: false,
    };
  },
  methods: {
    counterAdd(id, quantity) {
      this.loading = true;
      const data = new FormData();
      data.append("quantity", quantity + 1);
      data.append("_method", "PATCH");
      axios
        .post("/carts/" + id, data, {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then(() => {
          this.getCart();
          this.$store.state.cartCountProduct.product_count;
        })
        .finally(() => (this.loading = false));
    },
    counterMinus(id, quantity) {
      this.loading = true;
      const data = new FormData();
      data.append("quantity", quantity - 1);
      data.append("_method", "PATCH");
      axios
        .post("/carts/" + id, data, {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then(() => {
          this.getCart();
        })
        .finally(() => (this.loading = false));
    },
    showCart() {
      this.show = true;
      // if (this.show != false) {
      //   this.getCart();
      // }
    },
    hideCart() {
      this.show = false;
    },
    getCart() {
      if (this.lang == "en") {
        this.mainText = textEn;
      } else {
        this.mainText = textAr;
      }
      this.$store.dispatch("getCart");
    },
    deleateItem(id) {
      this.loading = true;
      axios
        .delete("/carts/" + id, {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then(() => {
          this.loading = false;
          this.getCart();
        });
    },
    copounCheck() {
      if (this.copounVal == "") {
        this.error.static.error = true;
        if (this.lang == "en") {
          this.error.static.text = "please enter your copoun";
        } else {
          this.error.static.text = "يرجي إدخال كود كوبون الخصم";
        }
      } else {
        const data = new FormData();
        data.append("coupon_code", this.copounVal);
        axios
          .post("/check-coupon", data, {
            headers: {
              Authorization: "bearer" + localStorage.getItem("shop-token"),
              "cache-control": "no-cache",
              "Content-type": "application/json",
              Accept: "application/json",
              lang: localStorage.getItem("shop_lang"),
            },
          })
          .then((res) => {
            this.copounVal = "";
            this.$store.state.cartList = res.data.data;
            this.otherData = res.data;
            this.error.static.error = false;
          })
          .catch((error) => {
            this.error.static.error = true;
            this.error.static.text = error.response.data.msg;
          });
      }
    },
    // gocheck() {
    //   if (this.error.static.error != true) {
    //     this.$router.push({ path: "/checkout" });
    //   }
    //   this.copounCheck();
    // },
  },
  components: {
    Loader,
    NotFound,
  },
  mounted() {
    this.getCart();
  },
};
</script>
